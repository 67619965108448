import React, { useState, useEffect, useRef, useContext } from "react";
import countries from 'countries-list';
import axios from "axios";
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';

import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import storage from '../../firebase'; // Importa el objeto storage que configuraste anteriormente
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"

import { URL_BACKEND } from "../../config";
import MiContexto from '../../MiContexto'

import { useTranslation } from 'react-i18next';



function Signup(props) {

    const { t } = useTranslation();

    const reseller = useContext(MiContexto).reseller

    const [percent, setPercent] = useState(0)
    const inputRef = useRef(null);
    const [avatar, setAvatar] = useState("https://catwatchful.com/avatarrrr56.jpg");


    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");

    const [referCode, setReferCode] = useState("");

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");

    const [country, setCountry] = useState("");
    const [phone, setPhone] = useState("");

    const [companyName, setCompanyName] = useState("");
    const [webSite, setWebSite] = useState("");

    const [aditionalInfo0, setAditionalInfo0] = useState('');
    const [aditionalInfo1, setAditionalInfo1] = useState("");
    const [aditionalInfo2, setAditionalInfo2] = useState("");


    const aditionalInfo3Options = [
        { value: 0, label: t('Signup.adi3_websBlogs') },
        { value: 1, label: t('Signup.adi3_youtube') },
        { value: 1, label: t('Signup.adi3_socials') },
        { value: 2, label: t('Signup.adi3_onlineMarketing') },
        { value: 3, label: t('Signup.adi3_contentMarketing') },
        { value: 4, label: t('Signup.adi3_influencers') },
        { value: 5, label: t('Signup.adi3_emailMarketing') },
        { value: 5, label: t('Signup.adi3_others') }]

    const [terms, setTerms] = useState(true);

    const [isLoading, setIsLoading] = useState(false);
    const [warnings, setWarnings] = useState([]);


    const navigate = useNavigate();

    const countryCodes = Object.keys(countries.countries);
    const countryNames = countryCodes.map(code => countries.countries[code].name);


    useEffect(() => {
        if (reseller) navigate('/dashboard')

        getCountry()
    }, [reseller])



    const handleSubmit = async (e) => {
        e.preventDefault()


        let warningsAux = []
        if (email.length === 0) {
            warningsAux.push(t('Signup.emailReq'))
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            warningsAux.push(t('Signup.emailInvalid'))
        }
        if (password.length === 0) {
            warningsAux.push(t('Signup.passReq'))
        } else if (password.length < 6) {
            warningsAux.push(t('Signup.passCharacters'))
        } else if (password !== password2) {
            warningsAux.push(t('Signup.passNotMatch'))
        }
        if (referCode.length < 5 || referCode.length > 10) warningsAux.push(t('Signup.referCodeCharactersNeed'))
        if (firstName.length === 0) {
            warningsAux.push(t('Signup.firstNameReq'))
        } else if (firstName.length < 3) {
            warningsAux.push(t('Signup.firstNameCharacters'))
        }
        if (lastName.length === 0) {
            warningsAux.push(t('Signup.lastNameReq'))
        } else if (lastName.length < 3) {
            warningsAux.push(t('Signup.lastNameCharacters'))
        }
        if (country.length === 0) warningsAux.push(t('Signup.countryReq'))
        if (phone.length <= 8) warningsAux.push(t('Signup.phoneReq'))
        if (aditionalInfo0.length === 0) warningsAux.push(t('Signup.marketingChannel'))
        if (aditionalInfo1.length === 0) warningsAux.push(t('Signup.descriveHowSell'))

        if (!terms) warningsAux.push(t('Signup.aceptConditions'))



        if (warningsAux.length === 0) {

            const language = (navigator.language || navigator.userLanguage).substring(0, 2).toLowerCase();
            const newReseller = { email: email, password: password, referCode: referCode.toUpperCase(), firstName: firstName, lastName: lastName, country: country, phone: phone, companyName: companyName, webSite: webSite, aditionalInfo0: aditionalInfo0, aditionalInfo1: aditionalInfo1, aditionalInfo2: aditionalInfo2, avatar: avatar, language: language }

            setIsLoading(true)
            const resp = await props.handleSignUp(newReseller)
            console.log(resp)

            if (resp.code === 'OK') {
                props.handleLogin(email, password)
            } else {
                setIsLoading(false)
                warningsAux.push(resp.message)
                setWarnings(warningsAux)
            }
        } else {
            setWarnings(warningsAux)
            window.scrollTo(0, 0)
        }

    }




    const handleSelectCountry = (e) => {
        const selectedCountryCode = e.target.value;
        setCountry(selectedCountryCode);
        console.log('País seleccionado:', selectedCountryCode);
    }

    const aditionalInfo3Change = (e) => {
        const selected = e.target.value;
        setAditionalInfo0(selected);
        console.log('aditionalInfo3Change:', selected);

    }


    const handleCancel = (e) => {
        e.preventDefault()
        navigate(-1)
    }
    const handleCheckBoxClick = (e) => {
        window.open("https://catwatchful.com/main/terms-of-use/");
    }


    const handleEmailOnChange = (e) => {
        setEmail(e)
        setReferCode(e.substring(0, 6).toUpperCase())

    }

    const validateReferCode = (referCode) => {
        const newValue = referCode.replace(/[^a-zA-Z0-9]/g, '');
        setReferCode(newValue.toUpperCase())
    }



    const getCountry = () => {
        fetch('https://api.ipregistry.co/?key=8y2ujul6j3svpe4w')
            .then(function (response) {
                return response.json();
            })
            .then(function (payload) {
                console.log(payload.location.country.code + ', ' + payload.location.city);
                setCountry(payload.location.country.code)
            });
    }



    const handleSeleccionarArchivo = () => {
        inputRef.current.click();

    };
    const handleAvatarChange = async (event) => {
        const file = event.target.files[0];
        const MAX_SIZE = 1000000; // 1MB

        if (file) {
            if (file.size > MAX_SIZE) { alert("The file is too large. Max size: 1MB"); return }

            try {
                // Subir la imagen al almacenamiento de Firebase Storage
                const storageRef = ref(storage, `/avatars/${file.name}`)
                const uploadTask = uploadBytesResumable(storageRef, file);

                uploadTask.on("state_changed", (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    setPercent(percent);
                },
                    (err) => console.log(err),
                    () => {
                        // download url
                        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                            console.log(url);
                            setPercent(0);
                            setAvatar(url)
                        });
                    }
                );
            } catch (error) {
                console.error('Error al subir el avatar:', error);
            }
        }
    };


    if (isLoading) return <div className="text-center " style={{ marginTop: "200px" }}><i className="fa fa-spinner fa-spin fa-3x fa-fw" /> {t('Signup.sendingData')} </div>


    return (

        <React.Fragment>


            <Fade>
                <div className="container-fluid mb-4 pb-3 col-12 col-md-11" >

                    <div >
                        <Flip><img src="./images/logo.svg" className="img-responsive " width="120px" alt="CatWatchful" /></Flip>
                        <Fade> <h5 className="mt-2 text-uppercase font-weight-bolder" style={{ color: "#8EDC80" }}>{t('Login.resellerProgram')}</h5></Fade>
                    </div>

                    <h4>{t('Signup.title1')} </h4>

                    <div className="d-flex justify-content-center">
                        <div className="col-12 col-md-6">
                            <p className="small text-left">{t('Signup.title2')} </p>
                            
                        </div>
                    </div>




                    <div className="d-flex justify-content-center">

                        <div className="col-12 col-md-6 text-left mx-1 mb-3 rounded d-flex justify-content-center fondoTransOscuro py-3">

                            <div className="col-12 col-md-9 mt-1">

                                <h5 className="text-uppercase text-center "  style={{ color: "#8EDC80" }}>{t('Signup.formTitle')} </h5>
                                <p className="small text-left text-secondary">{t('Signup.title3')} </p>
                                <hr className="bg-secondary  mb-4" />

                                {warnings.length > 0 && warnings.map((warning, index) => {
                                    return (
                                        <div key={index} className="alert alert-danger small" role="alert"><i className="fa fa-warning" />  {warning}</div>
                                    )
                                })}

                                <form onSubmit={handleSubmit}>

                                    <div className="form-group ">

                                        <label className="my-0 small" htmlFor="email">*Email:</label>
                                        <div className="input-with-icon">
                                            <input type="email" className="form-control"
                                                id="email" name="email"
                                                value={email} onChange={(e) => handleEmailOnChange(e.target.value)}
                                                placeholder={t('Signup.enterEmail')} />
                                            <i className='icon fa fa-envelope text-secondary' />
                                        </div>




                                        <label className="mt-2 mb-0 pb-0 small" htmlFor="password">*{t('Signup.password')}</label>
                                        <div className="input-with-icon mb-2">

                                            <input type="password" className="form-control mr-2"
                                                id="password" name="password"
                                                value={password} onChange={(e) => setPassword(e.target.value)}
                                                placeholder={t('Signup.enterPassword')} />
                                            <i className='icon fa fa-lock text-secondary' />
                                        </div>


                                        <div className="input-with-icon">
                                            <input type="password" className="form-control"
                                                id="password2" name="password2"
                                                value={password2} onChange={(e) => setPassword2(e.target.value)}
                                                placeholder={t('Signup.repeatPassword')} />
                                            <i className='icon fa fa-lock text-secondary' />
                                        </div>


                                        <label className="my-0 mt-3 small" htmlFor="referCode">*{t('Signup.selectReferCode')}</label>
                                        <div className="d-flex">
                                            <input type="text" className="form-control"
                                                id="referCode" name="referCode"
                                                value={referCode}
                                                onChange={(e) => validateReferCode(e.target.value)}
                                                placeholder={t('Signup.refecrCodePlaceHolder')}
                                                maxLength="10"
                                                style={{ width: "150px", textTransform: "uppercase", backgroundColor: "#7dbcff" }}
                                            />
                                            <span className="mt-2 ml-2 small"><small>({t('Signup.referCodeCharacters')})</small></span>
                                        </div>


                                        <div>
                                            <div className="text-left mt-2">


                                                <span className="btn btn-outline-light btn-sm mb-2 mt-1 py-0 mr-2 mt-4" onClick={handleSeleccionarArchivo} >{t('Signup.selectAvatar')}</span>
                                                {avatar && <img src={avatar} className="rounded-circle mt-2 " style={{ width: "75px", height: "75px" }} alt="Avatar" onClick={handleSeleccionarArchivo} />}
                                                <input type="file" accept="image/*" ref={inputRef} onChange={handleAvatarChange} style={{ display: 'none' }} />

                                                {percent != 0 && <div className="d-flex justify-content-center"><div style={{ width: "150px" }}>
                                                    <div className="progress mt-1">
                                                        <div className="progress-bar" role="progressbar" style={{ width: `${percent}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{percent}%</div>
                                                    </div> </div></div>}



                                            </div>

                                        </div>


                                        <hr className="my-3 bg-secondary" />

                                        <label htmlFor="firstName" className="my-0 small">*{t('Signup.firstName')}</label>
                                        <input type="text" className="form-control"
                                            id="firstName" name="firstName"
                                            value={firstName} onChange={(e) => setFirstName(e.target.value)}
                                            placeholder={t('Signup.enterFirstName')}/>

                                        <label htmlFor="lastName" className="mb-0 mt-2 small">*{t('Signup.lastName')}</label>
                                        <input type="text" className="form-control"
                                            id="lastName" name="lastName"
                                            value={lastName} onChange={(e) => setLastName(e.target.value)}
                                            placeholder={t('Signup.enterLastName')}/>


                                        <label htmlFor="country" className="mb-0 mt-2 small">*{t('Signup.country')}</label>
                                        <div>
                                            <select value={country} className="form-control form-select" aria-label="Default select example" id="selectCountry" onChange={handleSelectCountry} >
                                                <option value="">{t('Signup.selectCountry')}</option>
                                                {countryCodes.map((code, index) => (
                                                    <option key={code} value={code}>
                                                        {countryNames[index]}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>


                                        <label htmlFor="phone" className="mb-0 mt-2 small">*{t('Signup.phone')}</label>
                                        <PhoneInput
                                            country={country.toLowerCase()}
                                            searchPlaceholder={t('Signup.searchCountry')}
                                            onChange={phone => setPhone(phone)}
                                        />




                                        <label htmlFor="companyName" className="mb-0 mt-2 small">{t('Signup.companyName')}</label>
                                        <input type="text" className="form-control"
                                            id="companyName" name="companyName"
                                            value={companyName} onChange={(e) => setCompanyName(e.target.value)}
                                            placeholder={t('Signup.enterCompanyName')} />

                                        <label htmlFor="webSite" className="mb-0 mt-2 small">{t('Signup.webSite')}</label>
                                        <input type="text" className="form-control"
                                            id="webSite" name="webSite"
                                            value={webSite} onChange={(e) => setWebSite(e.target.value)}
                                            placeholder={t('Signup.enterWebSite')} />


                                        <hr className="my-3 bg-secondary" />

                                        <label htmlFor="aditionalInfo1" className="mb-0 mt-3 small">*{t('Signup.selectHowDistribute')}</label>
                                        <select value={aditionalInfo0} className="form-control form-select " aria-label="Default select example" id="selectCountry" onChange={aditionalInfo3Change} >
                                            <option value="">{t('Signup.selectOne')}</option>
                                            {aditionalInfo3Options.map((query, index) => (

                                                <option key={index} value={query.label}>
                                                    {query.label}
                                                </option>
                                            ))}
                                        </select>

                                        <label htmlFor="aditionalInfo1" className="mb-0 mt-3 small">*{t('Signup.selectHowPromote')}</label>
                                        <textarea type="text" className="form-control"
                                            id="aditionalInfo1" name="aditionalInfo1"
                                            value={aditionalInfo1} onChange={(e) => setAditionalInfo1(e.target.value)}
                                            placeholder="" />

                                        <label htmlFor="aditionalInfo2" className="mb-0 mt-3 small">{t('Signup.howDidHear')}</label>
                                        <textarea type="textarea" className="form-control"
                                            id="aditionalInfo2" name="aditionalInfo2"
                                            value={aditionalInfo2} onChange={(e) => setAditionalInfo2(e.target.value)}
                                            placeholder="" />




                                        <div className="form-check mt-3">
                                            <input type="checkbox" className="form-check-input" id="Check1" checked={terms} onChange={() => setTerms(!terms)} />
                                            <label className="form-check-label cheked text-warning small" htmlFor="Check1" onClick={handleCheckBoxClick}>{t('Signup.aceptTerms')}</label>
                                        </div>
                                        {/* submit button */}

                                        <div className="text-center">
                                            {/* <button className="btn btn-primary mt-3 " onClick={handleCancel}><i className="fa fa-times mr-1" />{t('Signup.cancelButton')}</button> */}
                                            <button type="submit" className="btn btn-success btn-block mt-4 ml-2 "><i className="fa fa-paper-plane mr-1" />{t('Signup.continueButton')}</button>
                                        </div>
                                    </div>



                                </form>



                            </div>
                        </div>
                    </div>
                </div>
            </Fade >
        </React.Fragment >
    )




}
export default Signup





