import {useTranslation } from 'react-i18next';


export default function Levels(props) {

    const { t } = useTranslation();

    const { current } = props

    const levels = [{ number: 1, title: "Bronze", earn: "25%", max: 0 },
    { number: 2, title: "Silver", earn: "30%", max: 10 },
    { number: 3, title: "Gold", earn: "40%", max: 20 },
    { number: 4, title: "Platinum", earn: "45%", max: 30 },
    { number: 5, title: "Diamond", earn: "50%", max: 40 }]



    const getStars = (levelNumber) => {
        let stars = []
        for (let i = 0; i < levelNumber; i++) {
            stars.push(<i className="fa fa-star" style={{ color: "#FFD700" }}></i>)
        }
        return (<span className="text-right">{stars}</span>)
    }

    return (
            <div className="d-flex justify-content-center my-2 ">


                <div className=" border border-secondary rounded " style={{ width: "330px" }}>
                    <div className="p-2 bg-dark">{t('Levels.gananciaxVta')}</div>
                    <hr className="my-0" />

                    <div className='list-group  list-group-flush '>

                        {levels.map((level, index) => {
                            return (
                                <div key={index} className="text-center text-warning ">

                                    {current === level.number ?
                                        <div className="d-flex justify-content-center  list-group-item py-1 fondoTransClaro">
                                            {getStars(level.number)}
                                            <span className="ml-1">{t('Levels.nivel')} {level.number}</span>
                                            <div className="ml-1" style={{ width: "90px" }}>{level.earn} </div>
                                            <span className=" pt-1 badge text-primary text-uppercase"><i className="fa fa-arrow-left " /> {t('Levels.nivelActual')} </span>
                                        </div>
                                        :
                                        <div className="d-flex justify-content-center list-group-item py-1 small bg-dark">
                                            <div className="text-right" style={{ width: "95px" }}>{getStars(level.number)}</div>
                                            <div style={{ width: "140px" }} className="mr-3">{t('Levels.nivel2')} {level.number}<span className="small text-secondary"> (+{level.max} {t('Levels.sales')})</span> </div>
                                            <div style={{ width: "70px" }}>{level.earn} </div>
                                        </div>
                                    }
                                </div>


                            )
                        })}
                    </div>

                </div>
            </div>
    )

}
