import {useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getChannelColor, getChannelIco, getChannelSteps } from "./channelOptions";



export function ConfirmNewChannelPrompt(props) {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const {channelName,channelAccount,channelType}=props

   console.log("ConfirmPrompt",props)


    const handleConfirm = () => {
        window.$('#confirmModal').modal('hide');
        props.handleConfirm()
    }
    return (
        <div className="modal fade text-dark" id="confirmModal" tabIndex="-1" role="dialog" aria-labelledby="confirmModal" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h6 className="modal-title" id="exampleModalLabel"><i className={`${getChannelIco(channelName)} mr-1 fa-2x`} />Confirmar {channelType} <span className='font-weight-bold'>{channelAccount}</span>?</h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="container">

                        <div className="text-dark text-left my-2">
                   
                            Confirma que el{channelType} de {channelName} es correcto y que eres el propietario?
                            <div className="text-dark mt-3"></div>
                        </div>

                        <div className="modal-footer mt-3">
                            <button type="button" className="btn btn-secondary mx-1" data-dismiss="modal">{t('Account.closeAccountBack')}</button>
                            <button type="submit" className="btn btn-primary" onClick={handleConfirm} >CONFIRMAR</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}






export function DeleteChannelPrompt(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const {channelName,channelAccount,channelType}=props

    const handleConfirmDelete = () => {
        window.$('#deleteChannelModal').modal('hide');
        props.handleConfirmDelete()
    }
    return (
        <div className="modal fade text-dark" id="deleteChannelModal" tabIndex="-1" role="dialog" aria-labelledby="deleteChannelModal" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h6 className="modal-title text-danger" id="exampleModalLabel"><i className={`${getChannelIco(channelName)} mr-1 fa-2x`} />Eliminar {channelType} <span className='font-weight-bold'>{channelAccount}</span>?</h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="container">

                        <div className="text-dark text-left my-2">
                        ¿Esta seguro que desea eliminar el canal {channelName} {channelAccount}?
                            <div className="text-dark mt-3"></div>
                        </div>

                        <div className="modal-footer mt-3">
                            <button type="button" className="btn btn-secondary mx-1" data-dismiss="modal">{t('Account.closeAccountBack')}</button>
                            <button type="submit" className="btn btn-primary" onClick={handleConfirmDelete} >CONFIRMAR</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )

}