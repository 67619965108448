import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';

export default function Footer(props) {

  const { t } = useTranslation();


  const currentLanguage = localStorage.getItem('i18nextLng').split('-')[0]
  let instagramLink = "https://www.instagram.com/catwatchful_es/"
  if (currentLanguage === 'en')instagramLink = "https://www.instagram.com/catwatchful_en/"
  let facebookLink = "https://www.facebook.com/cwatchful.esp"
  if (currentLanguage === 'en') facebookLink = "https://www.facebook.com/catsWatchful"
  let webSiteLink = "https://catwatchful.es/"
  if (currentLanguage === 'en') webSiteLink = "https://catwatchful.com/"


  let youtubeVideos = ['https://www.youtube.com/results?search_query=catwatchful']


  return (

    <div className={`footer text-secondary `}  >



      <div className="d-flex justify-content-center">
        <div className='d-flex justify-content-between' style={{ width: "170px" }}>

          {/* <span className="small">Redes:</span> */}

          <Link to={instagramLink} target="_blank"><i className=" fa fa-instagram mr-1 text-secondary"  /></Link >
          <Link to={facebookLink} target="_blank"><i className="fa fa-facebook-square mr-1 text-secondary" /> </Link >
          <Link to={webSiteLink} target="_blank"><i className="fa fa-globe mr-1 text-secondary" /> </Link >
          <Link to={youtubeVideos[0]} target="_blank"><i className="fa fa-youtube mr-1 text-secondary" /> </Link >

        </div>

      </div>
      <div className="mb-0 py-0 small"><small>&copy; {new Date().getFullYear()} {t('Footer.title')}</small></div>
      {/* <div className="mb-0 py-0 small">{t('Footer.message')}</div> */}
    </div>

  )


}