import React, { useEffect, useState, useRef, useContext } from "react";
import { Link } from 'react-router-dom'
import Moment from 'react-moment';
import Zoom from 'react-reveal/Zoom';

import { resizeNavBar } from '../functions'
import MiContexto from '../MiContexto'

import { QRCodeCanvas } from 'qrcode.react';
import clipboardCopy from 'clipboard-copy';

import { useTranslation } from 'react-i18next';

import CanvasJSReact from './../canvasjs/canvasjs.react'



export default function Sales(props) {
    resizeNavBar()

    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);

    const { t } = useTranslation();
    const reseller = useContext(MiContexto).reseller


    let paddingTop = "60px"
    if (props.externalCall === true) paddingTop = "0px"


    useEffect(() => {

        if (props.handleSalesNotifyViewed) {
            if (reseller.ventas.length > 0) {
                let salesNotify = reseller.ventas.filter(venta => venta.notify === 1)
                if (salesNotify.length > 0) {
                    //props.handleSalesNotifyViewed()
                }
            }
        }

    }, [reseller])

    const copiarTexto = (aText) => {
        clipboardCopy(aText)
            .then(() => {
                alert(t('Sales.linkCopied'));
            })
            .catch((error) => {
                console.error('Error al copiar el texto: ', error);
            });
    };


    if (!reseller) return null



    if (props.externalCall === true) {
        if (reseller.ventas.length === 0) {
            //return null
            return (
                <div className="container my-3 d-flex  justify-content-center ">
                    <div className="bg-dark rounded p-2" style={{ width: "365px" }}>{t('Sales.noSalesYet')}</div>
                </div>
            )
        }
    }



    let meses = [{ mes: "Enero", num: 1 }, { mes: "Febrero", num: 2 }, { mes: "Marzo", num: 3 }, { mes: "Abril", num: 4 }, { mes: "Mayo", num: 5 }, { mes: "Junio", num: 6 }, { mes: "Julio", num: 7 }, { mes: "Agosto", num: 8 }, { mes: "Septiembre", num: 9 }, { mes: "Octubre", num: 10 }, { mes: "Noviembre", num: 11 }, { mes: "Diciembre", num: 12 }]
    let graphSalesArray = []
    for (let i = 0; i < new Date().getDate(); i++) graphSalesArray.push(0)


    return (
        <Zoom>
            <div className="container mt-3 mb-0 " onClick={() => resizeNavBar()} style={{ paddingTop: paddingTop, paddingBottom: "100px" }}>

                <div className="d-flex justify-content-center">
                    <div className="bg-dark rounded border border-secondary" style={{ width: "365px" }}>

                        <div className="pt-2 h4 text-light">{t('Sales.sales')} {reseller.ventas.length}</div>

                        <div className="d-flex justify-content-center mb-1"  >

                            <table className="" style={{ width: "360px" }}>
                                <thead>
                                    <tr className="text-center small border-bottom border-secondary">
                                        <td className="text-light pl-2 pb-2" style={{ width: "90px" }}><span className="text-light ">{t('Sales.date')}</span></td>
                                        <td className="text-light pb-2 " style={{ width: "120px" }}   ><span className="text-light ">{t('Sales.product')}</span></td>
                                        <td className="text-light pb-2" style={{ width: "70px" }}><span className="text-light ml-2" >{t('Sales.price')}</span></td>
                                        <td className="text-light pb-2" style={{ width: "70px" }}><span className="text-light">{t('Sales.earn')} ({reseller.info.porcentajeGanancia}%)</span></td>
                                    </tr>
                                </thead>
                                <thead>
                                    {reseller.ventas?.map((venta, index) => {
                                        return (
                                            <React.Fragment key={index} >


                                                <tr className="small text-left " style={{ borderTop: "1px solid", borderTopColor: "#26292b", height: "30px" }} key={index} >

                                                    <td className="text-center " style={{ color: `#${venta.productColor}` }}>
                                                        {venta.notify === 1 && <span className="badge badge-danger ml-1 mb-1">{t('Sales.new')}!</span>}
                                                        <Moment className='mx-2 small' format="DD/MM/YYYY">{venta.datetime}</Moment>
                                                    </td>

                                                    <td className="text-light text-center">
                                                        {/* <img className="ml-1 my-2" src={`data:image/png;base64, ${venta.logo}`} style={{ width: "25px", height: "35px" }} /> */}
                                                        <span className="ml-1" style={{ color: `#${venta.productColor}` }}>{venta.productName}</span>
                                                    </td>
                                                    <td className="text-light "><span className="ml-3" style={{ color: `#${venta.productColor}` }}>u$s {venta.productPirce}</span></td>
                                                    <td className="text-light text-center pt-2">
                                                        <span className=' mx-2 bg-success p-1 rounded text-light' >u$s {venta.finalEarn}</span>
                                                        {venta.payed === 0 && <span className="badge badge-warning ml-1 ">{t('Sales.pendingPay')}</span>}
                                                        {venta.payed === 1 && <span className="badge badge-warning ml-1 ">{t('Sales.payed')} <i className="fa fa-check ml-1" /></span>}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td colSpan="4" className="text-secondary my-0 py-0">
                                                        <div className="d-flex small justify-content-center mb-3">
                                                            <i className="fa fa-user ml-1" />
                                                            <span style={{ fontSize: "10px" }} className="ml-1">{venta.deviceId} | {venta.userEmail}</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    })}
                                </thead>

                            </table>
                        </div>

                        {/* <hr className="mx-3 bg-secondary"  /> */}
                        <div className="mt-3 mb-2">
                            <span className="h4  text-success ">{t('Sales.totall')} U$S {reseller.totalVentas}</span>
                            {/* <Link to="/withdraw"><span className="small ml-2 text-success"><u>withdraw</u></span></Link> */}
                        </div>

                    </div>
                </div>



                <div className="my-3">
                    <Graficar amountsArray={graphSalesArray} type={"column"} title={`Ganancias ${meses[currentMonth - 1].mes.toUpperCase()}`} itemName={"Ventas"} xTag="" timeLapse="Dia" color={"#28a745"} />
                </div>

                {!props.externalCall && reseller.ventas.length === 0 &&

                    <div className="mt-4">

                        <h3 className="">{t('Sales.noSalesYet2')}</h3>
                        <h5>{t('Sales.shareLink')}</h5>

                        <div>
                            <Link to={`https://${reseller.info.referLink}`} target="_blank"><u className="text-warning h5 ">{reseller.info.referLink}</u></Link>
                            <i className="fa fa-clone ml-2" onClick={() => copiarTexto(reseller.info.referLink)} />
                        </div>


                        <div className="mt-4">
                            <QRCodeCanvas
                                value={`https://${reseller.info.referLink}`} renderAs="canvas"
                                includeMargin={true}
                                size={80} />

                        </div>

                        <div className="small" style={{ paddingTop: "10px" }}>
                            {t('Sales.comoVender')}<Link to="/howtouse"><u className="text-warning ">{t('Sales.entraAqui')}</u></Link>
                        </div>

                    </div>}

            </div>



        </Zoom>
    )



    function Graficar(props) {

        const CanvasJSChart = CanvasJSReact.CanvasJSChart;

        let { amountsArray, type, title, itemName, xTag, timeLapse, color, maximumY } = props
        if (!maximumY) maximumY = null

        if (amountsArray?.length > 0) {

            let cleanArray = ["null"]
            for (let i = 0; i < amountsArray.length; i++)cleanArray.push(parseFloat(amountsArray[i].toFixed(2)))

            const graphPoints = []
            for (let i = 1; i < cleanArray.length; i++)
                graphPoints.push({ x: i, y: cleanArray[i] })

            const graphOptions = {
                animationEnabled: true,
                theme: "dark2",
                title: {
                    text: title
                },
                axisX: {
                    title: timeLapse,
                    prefix: xTag,
                    interval: 1
                },
                axisY: {
                    title: itemName,
                    prefix: "",
                    minimum: 0,
                    maximum: maximumY
                },
                data: [{
                    toolTipContent: timeLapse + "{x} " + ": {y} " + itemName,
                    type: type,
                    showInLegend: true,
                    legendText: timeLapse,
                    dataPoints: graphPoints,
                    color: color,
                    lineThickness: 5,

                }]
            }

            return (

                <div className='my-0'>
                    <CanvasJSChart options={graphOptions} />
                </div>

            )

        } else {

            return (
                <h5 className="text-warning text-center mt-1">{"No " + "featureTitle" + " on " + "selectedMonth.title"}</h5>
            )

        }

    }


}