import React, { useEffect, useState, useRef, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import MiContexto from '../MiContexto'

import Moment from 'react-moment';

import storage from '../firebase'; // Importa el objeto storage que configuraste anteriormente
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import { resizeNavBar } from '../functions'

import Levels from "./Levels";

import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';

import {useTranslation } from 'react-i18next';



export default function Account(props) {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const reseller = useContext(MiContexto).reseller

    const [percent, setPercent] = useState(0)
    const inputRef = useRef(null);


    const [refresh, setRefresh] = useState(false);



    useEffect(() => {
        resizeNavBar()
        console.log("reseller: ", reseller)
    }, [reseller])




    const handleRefresh = () => {
        setRefresh(!refresh); // Cambia el valor de refresh
    };


    const handleNameChange = () => {
        window.$('#renameResellerModal').modal();
    }
    const handleReferCodeChange = () => {
        window.$('#changeCodeModal').modal();
    }
    const handleCloseAccount = () => {
        window.$('#closeAccountModal').modal();
    }
    const handleChangePassword = () => {
        window.$('#changePasswordModal').modal();
    }


    const handleAvatarChange = async (event) => {
        const file = event.target.files[0];

        const MAX_SIZE = 1000000; // 1MB
        if (file) {
            if (file.size > MAX_SIZE) { alert("The file is too large. Max size: 1MB"); return }

            try {
                // Subir la imagen al almacenamiento de Firebase Storage
                const storageRef = ref(storage, `/avatars/${file.name}`)
                const uploadTask = uploadBytesResumable(storageRef, file);


                uploadTask.on("state_changed", (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    setPercent(percent);
                },
                    (err) => console.log(err),
                    () => {
                        // download url
                        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                            console.log(url);
                            setPercent(0);

                            const resellerEdited = { avatar: url }
                            reseller.info.avatar = url
                            props.handleResellerEdit(resellerEdited)
                            handleRefresh()

                        });
                    }
                );

            } catch (error) {
                console.error('Error al subir el avatar:', error);
            }
        }
    };


    const handleSeleccionarArchivo = () => {
        inputRef.current.click();
    };


    if (!reseller) return null

    return (
        <div className="container" style={{ marginBottom: "70px" }} onClick={() => resizeNavBar()}>

            <RenameResellerPrompt oldName={reseller.info.nombres} handleResellerEdit={props.handleResellerEdit} />
            <ChangePasswordPrompt handleChangePassword={props.handleChangePassword} />
            <ChangeCodePrompt/>
            <CloseAccountPrompt/>
            
            
            {/* AVATAR*****/}
            <div className="text-center pt-4">
                {reseller.info.avatar &&
                    <div className="d-flex justify-content-center align-items-center   mb-2">
                        <Flip> <img src={reseller.info.avatar} className="rounded-circle" style={{ width: "120px", height: "120px" }} alt="Avatar" onClick={handleSeleccionarArchivo} /> </Flip>
                        <div><button className="btn btn-outline-light btn-sm ml-2 py-0" onClick={handleSeleccionarArchivo} >{t('Account.changeAvatar')}</button></div>
                    </div>
                }
                <input type="file" accept="image/*" ref={inputRef} onChange={handleAvatarChange} style={{ display: 'none' }} />
                {percent != 0 && <div className="d-flex justify-content-center"><div style={{ width: "150px" }}>
                    <div className="progress mt-1">
                        <div className="progress-bar" role="progressbar" style={{ width: `${percent}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{percent}%</div>
                    </div>
                </div>
                </div>}
            </div>
            {/* ******* */}




            {/* INFO CLAVE DEL REVENDEDOR */}
            <div className="d-flex justify-content-center">

                <div className="text-left ">
                    <div className="mt-0 " > <span className="mr-1 small">{t('Account.resellerName')}</span> <span className="text-warning">{reseller.info.nombres}</span> <i className="fa fa-pencil-square-o ml-1 text-light " onClick={handleNameChange} /></div>
                    <div className="my-2 " ><span className="mr-1 small">{t('Account.resellerCode')} </span><span className="text-warning">{reseller.info.referCode}</span> <i className="fa fa-pencil-square-o ml-1 text-light " onClick={handleReferCodeChange} /></div>
                    <div className="mt-0  " ><span className="mr-1 small">{t('Account.resellerUrl')}</span> <span className="text-warning">{reseller.info.referLink}</span></div>

                    <div className="text-center mt-2">
                        <div className="mt-1 badge badge-primary text-uppercase" >{t('Account.resellerLevel')} {reseller.info.nivel}</div>
                    </div>
                </div>
            </div>



            {/* OTROS DATOS DEL REVENDEDOR */}
            <div className="d-flex justify-content-center small mt-4 " >
                <div className=" text-left p-3  bg-dark rounded border border-secondary" style={{ width: "380px" }}>
                    {reseller.info.empresa && <div className="" ><i className="fa fa-group mr-1" />{t('Account.company')}   {reseller.info.empresa}</div>}
                    {reseller.info.website && <div className="" ><i className="fa fa-internet-explorer mr-2" />{t('Account.website')} {reseller.info.website}</div>}
                    <div className="" ><i className="fa fa-phone mr-2" />{t('Account.phone')}  {reseller.info.telefono}</div>
                    <div className="" ><i className="fa fa-globe mr-2" />{t('Account.country')} {reseller.info.pais}</div>
                    <div className="" ><i className="fa fa-shopping-cart mr-2" />{t('Account.channelSale')} {reseller.info.infoAdicional?.split(" | ")[0]}</div>

                    <div className="pt-3 small"><i className="fa fa-calendar-plus-o mr-2" />{t('Account.creation')} <Moment format="dddd Do, MMMM">{reseller.info.fecCreacion}</Moment></div>
                    <div className="pt-1 small"><i className="fa fa-calendar-check-o mr-2" />{t('Account.lastAccess')} <Moment format="dddd Do, MMMM, HH:mm A ">{reseller.info.fecUltimoAc}</Moment></div>
                </div>
            </div>



            <div style={{ marginTop: "25px" }}>
                <Levels current={reseller.info.nivel} />
            </div>


            <div>
                <button className="btn btn-outline-light btn-sm mt-4" style={{ width: "235px" }} onClick={handleChangePassword}><i className="fa fa-lock mr-1" />{t('Account.changePass')}</button>
            </div>

            <div>
                <button className="btn btn-outline-danger btn-sm mt-2" onClick={handleCloseAccount} style={{ width: "235px" }}><i className="fa fa-ban mr-1" />{t('Account.closeAccount')}</button>
            </div>


        </div>
    )




    function CloseAccountPrompt(props) {
        const handleChangeCodeRequest = () => {
            window.$('#closeAccountModal').modal('hide');
            navigate('/support')
        }
        return (
            <div className="modal fade text-dark" id="closeAccountModal" tabIndex="-1" role="dialog" aria-labelledby="closeAccountModal" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-danger" id="exampleModalLabel"><i className="fa fa-ban mr-1  " />{t('Account.closeAccountTitle')}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="container">

                            <div className="small text-dark text-left my-2">
                                <i className="fa fa-warning text-danger mr-1" />{t('Account.closeAccountMsj1')}
                                <div className="text-dark mt-3">{t('Account.closeAccountMsj2')}</div>
                            </div>

                            <div className="modal-footer mt-3">
                                <button type="button" className="btn btn-secondary mx-1" data-dismiss="modal">{t('Account.closeAccountBack')}</button>
                                <button type="submit" className="btn btn-primary" onClick={handleChangeCodeRequest} >{t('Account.closeAccountContinue')}</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }


    function ChangeCodePrompt(props) {
        const handleChangeCodeRequest = () => {
            window.$('#changeCodeModal').modal('hide');
            navigate('/support')
        }
        return (
            <div className="modal fade text-dark" id="changeCodeModal" tabIndex="-1" role="dialog" aria-labelledby="changeCodeModal" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-primary" id="exampleModalLabel"><i className="fa fa-pencil-square-o mr-1  " />{t('Account.changeReferCode')} </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="container">

                            <div className="small text-dark text-left my-2">
                                <i className="fa fa-warning mr-1" />{t('Account.changeReferCodeMsj1')}
                                <div className="text-dark mt-3">{t('Account.changeReferCodeMsj2')}</div>
                            </div>

                            <div className="modal-footer mt-3">
                                <button type="button" className="btn btn-secondary mx-1" data-dismiss="modal">{t('Account.changeReferCodeBack')}</button>
                                <button type="submit" className="btn btn-primary" onClick={handleChangeCodeRequest} >{t('Account.changeReferCodeContinue')}</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ChangePasswordPrompt(props) {

        const [newPass1, setNewPass1] = useState("")
        const [newPass2, setNewPass2] = useState("")

        const [warning, setWarning] = useState()
        const [isLoading, setIsLoading] = useState(false)

        const [passChangeOK, setPassChangeOK] = useState(false)

        const handleChangePassword = async (e) => {

            e.preventDefault()

            if (newPass1 !== newPass2) { setWarning(t('Account.passwordNotMatch')); return }
            if (newPass1.length < 6) { setWarning(t('Account.passwordCharacters') ); return }

            setIsLoading(true)
            const resp = await props.handleChangePassword(newPass1)
            setIsLoading(false)

            if (resp == 'OK') {
                setPassChangeOK(true)
            } else {
                setWarning(resp)
            }
        }

        return (
            <div className="modal fade text-dark" id="changePasswordModal" tabIndex="-1" role="dialog" aria-labelledby="changePasswordModal" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-primary" id="exampleModalLabel"><i className="fa fa-pencil-square-o mr-1  " />{t('Account.passwordChange')}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="container">
                            <div className="my-2 text-left">{t('Account.putNewPassword')}</div>
                            {/* <div className="small text-secondary text-left"><i className="fa fa-warning mr-1" />Este nombre es el que veran todos sus clientes cuando sean referenciados con su Codigo o Link</div> */}
                            {passChangeOK ? <div className="alert alert-success">{t('Account.passwordChangeOk')}<i className="fa fa-check ml-1" /></div>
                                :
                                <form onSubmit={handleChangePassword}>
                                    <div className='d-flex justify-content-center'>
                                        <input
                                            className='form-control my-1 mr-1'
                                            type="password"
                                            value={newPass1}
                                            onChange={(e) => setNewPass1(e.target.value)}
                                            placeholder={t('Account.newPassword')}
                                            maxLength={50}
                                        />
                                        <input
                                            className='form-control my-1'
                                            type="password"
                                            value={newPass2}
                                            onChange={(e) => setNewPass2(e.target.value)}
                                            placeholder={t('Account.repeatPassword')} 
                                            maxLength={50}
                                        />
                                    </div>
                                    {warning && <div className="alert alert-danger small my-1 mb-0" role="alert"><i className="fa fa-warning" />  {warning}</div>}
                                    {!isLoading ?
                                        <div className="modal-footer mt-3">
                                            <button type="button" className="btn btn-secondary mx-1" data-dismiss="modal">{t('Account.changePassBack')} </button>
                                            <button type="submit" className="btn btn-primary"  >{t('Account.changePassSave')} </button>
                                        </div>
                                        :
                                        <i className="spinner-border text-primary my-2" role="status" />
                                    }
                                </form>
                            }


                        </div>
                    </div>
                </div>
            </div>
        )

    }

    function RenameResellerPrompt(props) {
        const { oldName } = props

        const [newName, setNewName] = useState(oldName)
        const [warning, setWarning] = useState()
        const [isLoading, setIsLoading] = useState(false)

        const handleChangeName = (e) => {

            e.preventDefault()

            if (!newName || newName.trim().length < 5) { setWarning(t('Account.renameReseller') ); return }
            if (newName.trim().length > 20) { setWarning(t('Account.renameResellerCharacters')); return }

            const resellerEdited = { nombres: newName }
            reseller.info.nombres = newName
            props.handleResellerEdit(resellerEdited)
            handleRefresh()

            window.$('#renameResellerModal').modal('hide');

        }

        return (
            <div className="modal fade text-dark" id="renameResellerModal" tabIndex="-1" role="dialog" aria-labelledby="renameResellerModal" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-primary" id="exampleModalLabel"><i className="fa fa-pencil-square-o mr-1  " />{t('Account.newName')}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="container">
                            <div className="my-2 text-left">{t('Account.newNameSelect')}</div>
                            <div className="small text-secondary text-left"><i className="fa fa-warning mr-1" />{t('Account.newNameMsj')}</div>
                            <form onSubmit={handleChangeName}>
                                <div className='d-flex justify-content-center'>
                                    <input
                                        className='form-control my-1'
                                        type="text"
                                        value={newName}
                                        onChange={(e) => setNewName(e.target.value)}
                                        maxLength={50}
                                    />
                                </div>
                                {warning && <div className="alert alert-danger small my-1 mb-0" role="alert"><i className="fa fa-warning" />  {warning}</div>}
                                {!isLoading ?
                                    <div className="modal-footer mt-3">
                                        <button type="button" className="btn btn-secondary mx-1" data-dismiss="modal">{t('Account.newNameBack')} </button>
                                        <button type="submit" className="btn btn-primary"  >{t('Account.newNameContinue')}</button>
                                    </div>
                                    :
                                    <i className="spinner-border text-primary my-2" role="status" />
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }



}