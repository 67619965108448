import React, { useEffect, useState, useRef, useContext } from "react";
import { useNavigate,Link } from 'react-router-dom';
import { resizeNavBar } from '../functions'
import MiContexto from '../MiContexto'
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import { QRCodeCanvas } from 'qrcode.react';
import Levels from "./Levels";
import {useTranslation } from 'react-i18next';


export default function HowToUse(props) {

    const reseller = useContext(MiContexto).reseller

    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        resizeNavBar()

    }, [reseller])



    return (
        <div className="container small col-12 col-md-6" style={{ paddingTop: "30px", marginBottom: "100px" }} onClick={() => resizeNavBar()}>

            {/* <h4 className="text-success">GUÍA DE USO</h4> */}
            {/* <h4>Bienvenido a la Guía para Revendedores</h4> */}

            <Zoom><h4 className="text-uppercase">{t('HowToUse.ganaDinero')}</h4></Zoom>
            <p>{t('HowToUse.graciasPorUnirte')}</p>

            <div className="text-left">
                <Fade>
                    <div className="my-2">
                        <h5 className="text-warning"><i className="fa fa-chevron-right mr-1 small"/>{t('HowToUse.codigoDescuento')}</h5>
                        <div className="my-2">
                            <span className="bg-warning rounded border border-dark py-2 px-2 text-dark font-weight-bold "> {reseller.info.referCode}</span>
                        </div>
                        <p>{t('HowToUse.codigoExplain')}</p>
                        <p>{t('HowToUse.discountExplain')}.</p>
                    </div>
                </Fade>

                <Fade>
                    <div className="my-2">
                        <h5 className="text-warning"><i className="fa fa-chevron-right mr-1 small"/>{t('HowToUse.enlaceVta')}</h5>
                        <div className="my-2">
                            <Link to={'https://'+reseller.info.referLink} target="_blank"><span className="bg-warning rounded border border-dark py-2 px-2 text-dark font-weight-bold "> https://{reseller.info.referLink}</span></Link>
                        </div>
                        <p> {t('HowToUse.enlaceExplain')}</p>
                    </div>
                </Fade>


                <Fade>
                    <div className="my-2">
                        <h5 className="text-warning"><i className="fa fa-chevron-right mr-1 small"/>{t('HowToUse.codigoQr')}</h5>
                        <QRCodeCanvas
                            value={`https://${reseller.info.referLink}`} renderAs="canvas"
                            includeMargin={true}
                            size={80} />
                        <p>{t('HowToUse.codigoQrExplain')}</p>
                    </div>
                </Fade>

                <Fade>
                    <div className="my-2">
                        <h5 className="text-warning"><i className="fa fa-chevron-right mr-1 small"/>{t('HowToUse.niveles')}</h5>
                        <div className="d-flex justify-content-start">
                            <Levels current={reseller.info.nivel} />
                        </div>

                        <p>{t('HowToUse.nivelesExplain')}</p>
                    </div>
                </Fade>


                <Fade>
                    <div className="my-2">
                        <h5 className="text-warning"><i className="fa fa-chevron-right mr-1 small"/>{t('HowToUse.seguimientoYpagos')}</h5>
                        <p>{t('HowToUse.seguimientoYpagosExplain')}</p>
                        <p>{t('HowToUse.whitrawRequest')}</p>
                    </div>
                </Fade>


                <Fade>
                    <div className="my-2">
                        <h5 className="text-warning"><i className="fa fa-chevron-right mr-1 small"/>{t('HowToUse.soporteYrecursos')}</h5>
                        <p>{t('HowToUse.soporteYrecursosExplain')}</p>
                    </div>
                </Fade>
                <hr className="bg-light" />

                <Fade>
                    <div className="my-2">
                        <h5 className="text-light">{t('HowToUse.ganaHoy')}</h5>
                        <p>{t('HowToUse.ganaHoyExplain')}</p>
                    

                        <button className="btn btn-outline-light mt-0" onClick={()=>navigate('/support')} ><i className="fa fa-envelope ml-1"/> {t('HowToUse.soporte')}</button>
                    </div>
                </Fade>

            </div>



        </div>
    )
}
