import React, { useEffect, useState, useRef, useContext } from "react";
import Zoom from 'react-reveal/Zoom';
import Bounce from 'react-reveal/Bounce';
import Fade from 'react-reveal/Fade';
import { useNavigate, Link } from 'react-router-dom';
import { Step, NavigationButtons } from './SellingComponents'

import MiContexto from '../../MiContexto'

import clipboardCopy from 'clipboard-copy';
import { useTranslation } from 'react-i18next';

import { getChannelColor, getChannelIco, getChannelSteps } from "./channelOptions";
import { ConfirmNewChannelPrompt } from './Prompts'

//import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'


export default function TabFacebook(props) {

    const reseller = useContext(MiContexto).reseller
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [account, setAccount] = useState("")
    const [warning, setWarning] = useState(null)
    const [loading, setLoading] = useState(false)

    const sleepTime = (ms) => { return new Promise(resolve => setTimeout(resolve, ms)); }



    const [showFinalStep, setShowFinalStep] = useState(false)

    useEffect(() => {
        //console.log("showFinalStep", showFinalStep)
    }, [showFinalStep])

    const handleSetAccount = (value) => {
        if (value.length === 0) return setAccount("")
        //const newValue = "@" + value.replace("@", "").replace(" ", "")
        setAccount(value.toLowerCase())
    }


    const hanldeContinueClick = async () => {

        if (account.length === 0) return setWarning("Debes completar el campo")

        setWarning(null)
        setLoading(true)
        await sleepTime(3000)
        setLoading(false)

        const regex1 = /^[a-zA-Z0-9_]{1,30}$/; //Caracteres validos
        const regex2 = /^[0-9]+$/; //Solo numeros
        const isValidUsername = regex1.test(account) && !regex2.test(account);

        if (account.trim().length <= 5 || account.trim().length > 25 || !isValidUsername) {
            setWarning("Debe ingresar una cuenta valida")
        } else {
            window.$('#confirmModal').modal();
        }

    }

    const handleConfirm = async () => {

        setWarning(null)
        setLoading(true)

        const channel = { name: "Facebook", account: '/'+account, url: account, url: `https://www.facebook.com/${account}` }
        await sleepTime(1000)
        const resp = await props.handleNewChannel(channel)
        setLoading(false)
        if (resp.code === "OK") {
            setShowFinalStep(true)
        } else if (resp.code === "DUP") {
            setWarning(`La cuenta ${account} ya se encuentra registrada`)
            setShowFinalStep(false)
        } else {
            setWarning("Error." + resp)
            setShowFinalStep(false)
        }
    }

    return (
        <div>

            <ConfirmNewChannelPrompt handleConfirm={handleConfirm} channelName="Facebook" channelAccount={account} channelType={"cuenta"} />


            {!showFinalStep && <Fade left>

                <Step number='2' />
                <div className="mt-3 bg-dark p-3 " style={{ borderRadius: '15px', border: `2px solid ${getChannelColor("Facebook")}` }}>

                    <div className="h4 mt-3" style={{ color: getChannelColor("Facebook") }}><i className={`${getChannelIco("Facebook")} mr-2 fa-3x`} />FACEBOOK</div>

                    <div className="mb-1 mt-0 text-left text-center">Ingresa la cuenta en la que vas a promocionar:</div>

                    <div className="d-flex mt-3 justify-content-center">
                        <div className="pt-1 ml-2 mr-1 h6" style={{ color: getChannelColor("Facebook") }}>facebook.com/</div>
                        <div className="input-with-icon">
                            <input type="text" className="form-control pl-1" style={{ color: getChannelColor("Facebook"), height: "28px" }}
                                id="firstName" name="firstName"
                                value={account} onChange={(e) => handleSetAccount(e.target.value)}
                                placeholder="" />
                            <i className={`icon ${getChannelIco("Facebook")} text-secondary`} />
                        </div>
                    </div>

                    <span className="small">Ejemplo: facebook.com/juan_perez</span>


                    {warning && <div className="alert alert-danger  my-2" role="alert"><i className="fa fa-warning" />  {warning}</div>}
                    <div className="text-secondary text-left small mt-2">
                        <div>Puedes utilizar tu cuenta personal o crear una cuenta exclusiva para promocionar. Si utilizas una cuenta exclusiva puedes incluir tu enlace de ventas en la info de la cuenta.</div>
                        {/* <div className="my-1">Comparte nuestras publicaciones en tu cuenta de Facebook y gana dinero por cada vez que alguien compre la aplicación utilizando tu enlace de ventas o codigo de descuento.</div> */}
                    </div>






                    {!loading ? <div className="mt-4">
                        <NavigationButtons handleBack={() => props.setCurrentTab("SelectChannel")} handleContinue={hanldeContinueClick} />
                    </div>
                        :
                        <div className="mt-4">
                            <div className="text-center"><i className="spinner-border text-primary my-2" role="status" /></div>
                            <div className="text-secondary">Validado cuenta...</div>
                        </div>}

                </div>
            </Fade>}

            {showFinalStep && <FacebookFinalStep account={account} handleReload={props.handleReload} />}
        </div>

    )

    function FacebookFinalStep(props) {



        return (
            <Bounce left>

                <Step number='3' />

                <div className="mt-3 bg-success p-3 border border-light rounded">
                    <Confetti
                        width={380}
                        height={300}
                        recycle={false}
                    />

                    <div className="bg-light py-1 rounded mb-3" style={{ color: getChannelColor("Facebook") }}>
                        <div className="h4 mt-3  " ><i className={`fa ${getChannelIco("Facebook")} mr-2 fa-3x`} />FACEBOOK</div>
                        <div className=" font-weight-bold mb-4 h5 px-2">YA PUEDES PROMOCIONAR CATWATCHFUL EN {'/'+props.account}</div>
                    </div>
                    {getChannelSteps("Facebook")}


                </div>

                <div className="mt-4">
                    <button className="btn btn-primary" onClick={() => props.handleReload()}>Finalizar <i className="fa fa-check" /></button>
                </div>
            </Bounce>

        )
    }
}