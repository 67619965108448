import {useTranslation } from 'react-i18next';


export function PendingAprovalPromt() {

    const { t } = useTranslation();

    return (
        <div className="modal fade" id="pendingAprovalModal" tabIndex="-1" role="dialog" aria-labelledby="pendingAprovalModal" aria-hidden="true" data-backdrop="static" data-keyboard={false}>
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content text-dark">
                    <div className="modal-header">
                        <h5 className="modal-title text-danger text-uppercase" id="exampleModalLabel">{t('PromptsStatus.underReview')} <i className="fa fa-search mr-1" /></h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body text-left small">
                        <div>{t('PromptsStatus.requestSent')} </div>
                        <div>{t('PromptsStatus.requestSentMsj1')}</div>

                        <hr />
                        <p>{t('PromptsStatus.requestSentMsj2')}</p>

                        <div className="mt-3">{t('PromptsStatus.requestSentMsj3')}</div>

                    </div>
                    <div className="modal-footer">
                        <span className='small mb-0'>{t('PromptsStatus.requestSentContact')} </span>
                        <a className="small " href="mailto:reseller@catwatchful.com"><i className="fa fa-envelope"/> reseller@catwatchful.com</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function AprovalSuccessfullyPromt() {

    const { t } = useTranslation();

    return (
        <div className="modal fade text-dark" id="aprovalSuccessfullyModal" tabIndex="-1" role="dialog" aria-labelledby="aprovalSuccessfullyModal" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-success text-uppercase" id="exampleModalLabel">{t('PromptsStatus.requestAproved')} <i className="fa fa-check mr-1" /></h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body text-left small">
                        <div className="h6 text-uppercase">{t('PromptsStatus.requestAprovedCongrats')} </div>
                        <div>{t('PromptsStatus.requestAprovedMsj1')} </div>
                        <div className="mt-3">{t('PromptsStatus.requestAprovedMsj2')}</div>
                        <div className="mt-3">{t('PromptsStatus.requestAprovedMsj3')}</div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" data-dismiss="modal" >{t('PromptsStatus.requestAprovedClose')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}