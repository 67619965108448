import React, { useEffect, useState, useRef, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { resizeNavBar } from '../functions'
import MiContexto from '../MiContexto'
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import { QRCodeCanvas } from 'qrcode.react';
import Levels from "./Levels";
import { useTranslation } from 'react-i18next';


function Support(props) {

    const { t } = useTranslation();

    const reseller = useContext(MiContexto).reseller
    const [isLoading, setIsLoading] = useState(false)


    const queryType = [
        { value: 0, label: t('Support.queryType0') },
        { value: 1, label: t('Support.queryType1') },
        { value: 2, label: t('Support.queryType2') },
        { value: 3, label: t('Support.queryType3') },
        { value: 4, label: t('Support.queryType4') },
        { value: 5, label: t('Support.queryType5') },
        { value: 6, label: t('Support.queryType6')}]

    const [selectedQuery, setSelectedQuery] = useState('')
    const [queryComment, setQueryComment] = useState('')

    const [sendOk, setSendOk] = useState(false)

    const [warnings, setWarnings] = useState([]);


    const handleSelectChange = (e) => {
        const selectedQuery = e.target.value;
        console.log(selectedQuery)
        setSelectedQuery(selectedQuery);
    }


    const handleSendClick = async () => {
        let warnings = []
        if (selectedQuery === '') {
            warnings.push(t('Support.selectQueryType') )
        }
        if (queryComment === '') {
            warnings.push(t('Support.putQuery') )
        }
        if (warnings.length > 0) {
            setWarnings(warnings)
            return
        }


        let query = { type: selectedQuery, comment: queryComment }
        setIsLoading(true)
        const resp = await props.handleResellerQuery(query)
        setIsLoading(false)

        if (resp == 'OK') {
            setSendOk(true)
        } else {
            setWarnings([resp])
        }

    }



    useEffect(() => {
        resizeNavBar()

    }, [reseller])



    return (
        <div className="py-4" onClick={() => resizeNavBar()} >

            <Zoom><h3 className="mt-4"><i className="fa fa-wrench mr-2" />{t('Support.title')}</h3></Zoom>

            {sendOk ?
                <div className="alert alert-success mx-3 my-4" role="alert">
                    <div className="font-weight-bold">{t('Support.querySent')}<i className="fa fa-check fa-2x ml-1" /> </div>
                    <div className="mt-2 small">{t('Support.querySentMsj')}</div>
                </div>
                :
                <div className="container mt-4 col-12 col-md-6" >

                    {/* <label htmlFor="country" className="mb-0 mt-2">TIPO DE CONSULTA:</label> */}
                    <Fade>
                        <div>
                            <select value={selectedQuery} className="form-control form-select" aria-label="Default select example" id="selectCountry" onChange={handleSelectChange} >
                                <option value="">{t('Support.typeOfQuery')}</option>
                                {queryType.map((query, index) => (

                                    <option key={query.value} value={query.label}>
                                        {query.label}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="my-4">
                            {/* <label htmlFor="aditionalInfo2" className="mb-0 mt-2">Deje su comentario</label> */}
                            <textarea type="textarea" className="form-control"
                                id="aditionalInfo2" name="aditionalInfo2"
                                value={queryComment} onChange={(e) => setQueryComment(e.target.value)}
                                placeholder={t('Support.putQueryPlaceHolder')}
                                rows="7"
                            />
                        </div>

                    </Fade>

                    {warnings.length > 0 && warnings.map((warning, index) => {
                        return (
                            <div key={index} className="alert alert-danger small" role="alert"><i className="fa fa-warning" />  {warning}</div>
                        )
                    })}

                    <div className="my-4">
                        {!isLoading ?
                            <button className="btn btn-block btn-outline-light" onClick={handleSendClick} disabled={false}><i className="fa fa-send mr-1" />{t('Support.btnSend')}</button>
                            :
                            <button className="btn btn-block btn-outline-light" onClick={handleSendClick} disabled={true}><i className="fa fa-spinner fa-spin mr-1" />{t('Support.btnSending')} </button>
                        }
                    </div>


                </div>
            }


        </div>
    )
}
export default Support