import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyBVQYU9-rygrs-NQhOdhtY1iC02JOq_l0U",
  authDomain: "catwatchful-e03b8.firebaseapp.com",
  projectId: "catwatchful-e03b8",
  storageBucket: "catwatchful-e03b8.appspot.com",
  messagingSenderId: "290254967732",
  appId: "1:290254967732:android:1be5a596e0649f3e"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export default  storage ;

