import React, { useState, useEffect, useContext } from 'react'

import MetisMenu from 'react-metismenu';
import './NavBar.css'
import { resize, openNavBar, closeNavBar, resizeNavBar } from '../functions'

import { useTranslation } from 'react-i18next';



export default function NavBar(props) {

    const { t } = useTranslation();

    const { reseller } = props
    const [menuContent, setMenuConten] = useState({ items: [] })


    const getLinkText = () => {
        if (reseller.channels.length==0 ) {
            return "Comenzar a Vender"
        }else {
            return  "Canales de promoción"
        }
    }

    useEffect(() => {

        window.addEventListener("resize", resize.bind(resizeNavBar()));

        setMenuConten({
            items: [
                {
                    icon: 'tachometer ',
                    label: t('NavBar.dashboard'),
                    to: '#dashboard',
                },
                {
                    icon: 'user-circle fa-1x ',
                    label: t('NavBar.account'),
                    to: '#account',
                },
                {
                    icon: 'star fa-1x text-warning',
                    label: <span className=''>{getLinkText()}</span>,
                    to: '#startSelling',
                },
                
                {
                    icon: 'line-chart fa-1x ',
                    label: t('NavBar.sales'),
                    to: '#sales',
                },
                {
                    icon: 'usd fa-1x  ',
                    label: t('NavBar.withdraw'),
                    to: '#withdraw',
                },


                {
                    label: '_____________________', // <-- this is a divider
                },
                // {
                //     icon: 'bullhorn fa-1x ',
                //     label: <span className=''>{t('NavBar.resources')}</span>,
                //     to: '#resources',
                // },

                {
                    icon: 'question-circle fa-1x  ',
                    label: t('NavBar.houtouse'),
                    to: '#howtouse',
                },
                {
                    icon: 'android fa-1x',
                    label: <span className=''>Que Promocionar?</span>,
                    to: '#about',
                },
                {
                    icon: 'wrench fa-1x',
                    label: <span className=''>{t('NavBar.support')}</span>,
                    to: '#support',
                },
                {
                    icon: 'sign-out fa-1x text-danger',
                    label: <span className='text-danger small'>{t('NavBar.logout')}</span>,
                    to: '#logout',
                },
            ]
        })

    }, [reseller])
    //---------------------


    return (

        <React.Fragment>
            {/* ICON OPEN SIDE BAR */}
            <div className="col-1 col-md-1 p-0 ">
                <button className="openbtn mr-0 w-100 h-100 pt-2 fondoTransOscuro" onClick={() => openNavBar()}> <span className="h3 rounded-lg pt-1 px-1 colorVerdeDolar">☰</span> </button>
            </div>



            <div id="mySidebar" className="sidebar ">
                <a href="javascript:void(0)" className="closebtn text-white  " style={{ backgroundColor: "#1a1b1f" }} onClick={() => closeNavBar()}>×</a>

                <div className="text-center my-2 mx-2">
                    <a className='' style={{ backgroundColor: "#1a1b1f" }} href="javascript:void(0)" onClick={() => closeNavBar()} > <img src="./images/logo.svg" className="img-responsive" width="80px" alt="CatWatchful" /></a>
                    <span className='colorVerdeDolar h6 text-uppercase mb-4'>{t('NavBar.resellerProgram')}</span>
                    {/* <div><span className='colorVerdeDolar small'>{reseller.info.email}</span></div> */}



                    <div className='my-3 '>
                        <div className='h6 font-weight-bold mt-2 ' style={{lineHeight:"8px"}} >{reseller.info.referCode}</div>
                        <div className='small ' style={{lineHeight:"8px"}} ><small>{reseller.info.referLink}</small></div>
                    </div>


                    {reseller.info.estado === 1 &&
                        <div className=''>
                            <span className="colorVerdeDolar font-weight-bold" style={{ fontSize: "11px" }}>{t('NavBar.aprovada')} <i className="fa fa-check ml-0 " /></span>
                            <span className="text-secondary ml-2 " style={{ fontSize: "11px" }}>({t('NavBar.nivel')} {reseller.info.nivel})</span>
                        </div>}
                    {reseller.info.estado != 1 &&
                        <span className="text-warning font-weight-bold" style={{ fontSize: "13px" }}><i className="fa fa-warning mr-2 " />{t('NavBar.pendingAproval')}</span>}



                </div>

                {/* NAV BAR ----------- */}
                <div className='text-left'>
                    <MetisMenu iconNameStateVisible="minus" iconNameStateHidden="plus" content={menuContent.items} activeLinkFromLocation />
                </div>
                {/* ------------------ */}


            </div>
        </React.Fragment>


    )




}




