import React, { useState, useEffect, useContext } from "react";
import {  Link } from 'react-router-dom';
import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';

import {  useTranslation } from 'react-i18next';



function Login(props) {

    const { t } = useTranslation();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [advertencia, setAdvertencia] = useState();
    const [isLoading, setIsLoading] = useState(false);

   

    const handleLogin = async (event) => {

        event.preventDefault()

        setIsLoading(true);

        if (username && password) {
            setAdvertencia(null)
            const resp = await props.handleLogin(username, password)
            //setIsLoading(false);
            if (!resp) {
                setAdvertencia(t('Login.incorrect') )
                setIsLoading(false);
            }

            console.log("resp: ", resp)
        } else {
            setIsLoading(false);
            setAdvertencia(t('Login.putUser') )
        }

    };


    return (
        <div>

            <div className="container" style={{ marginTop: '120px' }}>


                <div className='d-flex justify-content-center'>
                    <div >
                        <Flip><img src="./images/logo.svg" className="img-responsive " width="120px" alt="CatWatchful" /></Flip>
                        <Fade> <h5 className="mt-2 text-uppercase font-weight-bolder" style={{ color: "#8EDC80" }}>{t('Login.resellerProgram')}</h5></Fade>
                    </div>

                </div>

                {/* <h5 className="" style={{color:"#FFC75F" }}>LOGIN</h5> */}

                <Fade>
                    <div className="d-flex justify-content-center">
                    <div className="fondoTransOscuro rounded mt-4 col-md-5">

                        <div className="pt-3 text-secondary"><i className="fa fa-user text-right mr-1 " />{t('Login.loginTitle')}</div>

                        <form onSubmit={handleLogin}>
                            <div className='d-flex justify-content-center px-4 pt-3'>
                                <div className='form-group' style={{ width: '400px' }}>

                                    <div className="input-with-icon">
                                        <input
                                            className='form-control my-1'
                                            type="text"
                                            placeholder="E-mail"
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                        />
                                        <i className='icon fa fa-envelope text-secondary' />
                                    </div>

                                    <div className="input-with-icon">
                                        <input
                                            className='form-control'
                                            type="password"
                                            placeholder={t('Login.password')}
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        <i className='icon fa fa-lock text-secondary' />
                                    </div>

                                    {advertencia && <div className="alert alert-danger small my-2" role="alert"><i className="fa fa-warning" />  {advertencia}</div>}

                                    {!isLoading ?
                                        < button className='form-control btn btn-success mt-1' disabled={false}>{t('Login.login')}</button>
                                        :
                                        <button className='form-control btn btn-success mt-1' disabled={true}><i className="fa fa-spinner fa-spin" /> </button>
                                    }

                                </div>
                            </div>
                            <div className="text-right mx-4 py-2 ">
                                <Link to="forgotPass"><div className="small text-secondary ">{t('Login.forgotPassword')}</div></Link>

                            </div>
                        </form >
                    </div>
                    </div>
                </Fade>
                {/* <Link to="signup"><div className=" small text-secondary ml-2"><i className="fa fa-user-plus mr-1" />Become a Reseller</div></Link> */}
            </div >




        </div>
    )

}
export default Login