import React, { useEffect, useState, useRef, useContext } from "react";

import { useNavigate, Link } from 'react-router-dom';
import MiContexto from '../MiContexto'
import Zoom from 'react-reveal/Zoom';


import {useTranslation } from 'react-i18next';


export default function Settlement(props) {

    const { t } = useTranslation();
    const navigate = useNavigate();


    const reseller = useContext(MiContexto).reseller


    useEffect(() => {
    }, [reseller])



    const handleWithDrawClick = () => {
        navigate('/withdraw');
    }

    // let visitas = reseller.visitas
    // let gananciaPorVisita = 0.01
    // let gananciaVisitas = parseInt(visitas) * gananciaPorVisita



    return (
        <Zoom>
            <div className="container d-flex justify-content-center ">

                <div className=" border border-secondary rounded mt-2 text-left bg-dark" style={{ width: "365px" }}>
                    <div className="text-center pt-2   h4 text-light ">{t('Settlement.credit')}</div>
                    <hr className="bg-secondary mt-0 mx-2" />

                    <div className="mx-2 small ">

                        
                        <div className="h6 mb-0"><i className="fa fa-check-square small text-success"/> {t('Settlement.gananciaVtas')} <span className='text-success font-weight-bolder' >U$S {reseller.totalVentas}</span> </div>
                        <div className=" ml-3  my-1 text-secondary"><i className="fa fa-dot-circle-o text-secondary "/> {t('Settlement.totalVtas')} <span className='text-warning ' >{reseller.ventas.length}</span></div>
                        <div className=" ml-3 text-secondary"><i className="fa fa-dot-circle-o  text-secondary "/> {t('Settlement.pteGanancia',{nivel:reseller.nivel})}<span className='text-warning ml-1' >{reseller.porcentajeGanancia}%</span> </div>
                        
                        {/* <div className="h6 mb-0"><i className="fa fa-check-square small text-success mt-4"/> Bonificación por visitas: <span className='text-success font-weight-bolder' >U$S {gananciaVisitas}</span> </div>
                        <div className=" ml-3  my-1 text-secondary"><i className="fa fa-dot-circle-o text-secondary "/> Total de visitas: <span className='text-warning ' >{visitas}</span></div>
                        <div className=" ml-3 text-secondary"><i className="fa fa-dot-circle-o  text-secondary "/> Ganancia por cada visita:<span className='text-warning ml-1' >U$S {gananciaPorVisita}</span> </div> */}

                        <div className="h6"><i className="fa fa-check-square small text-success mt-4"/> {t('Settlement.bonoInicial')} <span className=' text-success font-weight-bolder' >U$S {reseller.bono}</span></div>

                        <hr className="bg-secondary" />
                    </div>


                    <div className="text-center">
                        <div className="my-0 py-0">{t('Settlement.totalDisp')}</div>
                        <span className="text-success " style={{ fontSize: "50px" }}>U$S {reseller.saldo}</span>
                        {props.externalCall&&<button className="btn btn-success btn-sm ml-2 mb-4 py-1" onClick={handleWithDrawClick}><i className="fa fa-credit-card mr-1" />{t('Settlement.retirar')}</button>}
                    </div>


                </div>
            </div>
        </Zoom>
    )

}