import React, { useEffect, useState, useRef, useContext } from "react";
import { useNavigate, Link } from 'react-router-dom';
import { resizeNavBar } from '../functions'
import MiContexto from '../MiContexto'
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import { useTranslation } from 'react-i18next';

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'




export default function Resources(props) {

    const reseller = useContext(MiContexto).reseller

    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        resizeNavBar()
    }, [reseller])



    const currentLanguage = localStorage.getItem('i18nextLng').split('-')[0]
    let instagramLink = "https://www.instagram.com/catwatchful_es/"
    if (currentLanguage === 'en') instagramLink = "https://www.instagram.com/catwatchful_en/"
    let facebookLink = "https://www.facebook.com/cwatchful.esp"
    if (currentLanguage === 'en') facebookLink = "https://www.facebook.com/catsWatchful"
    let webSiteLink = "https://catwatchful.es/"
    if (currentLanguage === 'en') webSiteLink = "https://catwatchful.com/"


    let youtubeVideos = [
        { channel: "TecNìvoro", url: 'https://www.youtube.com/watch?v=aErVewrtl9M' },
        { channel: "Alexius Tv", url: 'https://www.youtube.com/watch?v=k7IrYX62_k0' },
        { channel: "Rincón Tecnológico", url: 'https://www.youtube.com/watch?v=uUD8pI6Zhao' },
        { channel: "RickWTF", url: 'https://www.youtube.com/watch?v=gyaQKUdrcTA' }
    ]


    const spanStyle = {
        padding: '20px',
        background: '#FFF',
        color: '#000000'
    }
    const divStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundSize: 'cover',
        height: '200px'
    }

    const slideImages = [
        {
            url: 'images/baner1.jpg',
            caption: <span>{t('Resources.banner1Text')} <span className="font-weight-bold">{reseller.info.referCode}</span></span>
        },
        {
            url: 'images/baner2.jpg',
            caption: <span>{t('Resources.banner2Text')} <a className="text-dark" href={'https://' + reseller.info.referLink} target="_blank"><u>{reseller.info.referLink}</u></a></span>
        },
        {
            url: 'images/baner3.jpg',
            caption: <span>{t('Resources.banner3Text')}</span>
        },
        {
            url: 'images/baner4.jpg',
            caption: <span>{t('Resources.banner4Text1')} <span className="font-weight-bold">{reseller.info.referCode}</span> {t('Resources.banner4Text2')}</span>
        },
    ];


    return (
        <div className="container small col-12 col-md-6" style={{ paddingTop: "30px", marginBottom: "60px" }} onClick={() => resizeNavBar()}>

            <Zoom>
                {/* <h4> <div className="text-uppercase"><i className="fa fa-bullhorn mr-2 mt-3" />Recursos Oficiales</div></h4> */}

                <div className="slide-container">
                    <Slide>
                        {slideImages.map((slideImage, index) => (
                            <div key={index}>
                                <div style={{ ...divStyle, 'backgroundImage': `url(${slideImage.url})` }}>
                                    <span style={spanStyle}>{slideImage.caption}</span>
                                </div>
                            </div>
                        ))}
                    </Slide>
                </div>

            </Zoom>
            <Zoom >
                <div className="mt-2">
                    <div className="text-left p-3">

                        <p className="my-0">{t('Resources.tag1')} </p>
                        <p className="my-2">{t('Resources.tag2')} <span className="text-warning">{reseller.info.referCode}</span> {t('Resources.tag22')}</p>
                        <p className="my-0">{t('Resources.tag3')}</p>

                    </div>

                    <hr className="bg-secondary" />

                </div>
            </Zoom>


            <div className="text-left mt-lg-4 p-2">
                <Fade>
                    <div className="my-2">


                        <div className="my-2 ">
                            <Link className="text-warning" to={instagramLink} target="_blank">
                                <i className="fa fa-instagram fa-2x mr-1 mb-1" /> <span className="font-weight-bold h6">
                                    Instagram<i className="fa fa-link small ml-1" />
                                </span>
                            </Link >
                            <p className="">{t('Resources.insta')}</p>
                        </div>
                        <div className="my-2">
                            <Link className="text-warning" to={facebookLink} target="_blank">
                                <i className="fa fa-facebook-square fa-2x mr-1 mb-1" />
                                <span className="font-weight-bold h6">Facebook<i className="fa fa-link small ml-1" /></span>
                            </Link >
                            <p>{t('Resources.feis')}</p>
                        </div>

                        <div className="my-2">
                            <Link className="text-warning" to={webSiteLink} target="_blank">
                                <i className="fa fa-globe fa-2x mr-1 mb-1 " />
                                <span className="font-weight-bold h6">Website<i className="fa fa-link small ml-1" /></span>
                            </Link >
                            <p>{t('Resources.web')}</p>
                        </div>

                        <div className="my-2">
                            <Link className="text-warning" to={'https://www.youtube.com/results?search_query=catwatchful'} target="_blank">
                                <i className="fa fa-youtube fa-2x mr-1 mb-1" />
                                <span className="font-weight-bold h6">Youtube<i className="fa fa-link small ml-1" /></span>
                            </Link >
                            <p>{t('Resources.youtube')}</p>

                            {t('Resources.ejemplos')}
                            {youtubeVideos.map((video, index) => {
                                return (
                                    <div key={index}>
                                        <a className="text-warning" href={video.url} target="_blank"><i className="fa fa-youtube" /> {`${video.channel}`}</a>
                                    </div>
                                )
                            })
                            }
                        </div>


                    </div>
                </Fade>

            </div>




        </div>
    )
}
