import React, { useEffect, useState, useRef, useContext } from "react";
import { useNavigate, Link } from 'react-router-dom';
import { resizeNavBar } from '../../functions'
import MiContexto from '../../MiContexto'
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
import { Step } from './SellingComponents'
import SelectChannel from './SelectChannel'



import { useTranslation } from 'react-i18next';


import axios from "axios";
import { URL_BACKEND } from "../../config";
import { getChannelColor, getChannelIco, getChannelSteps } from "./channelOptions";



export default function StartSelling(props) {

    const reseller = useContext(MiContexto).reseller
    const user = useContext(MiContexto).user

    //const [channel, setChannel] = useState("")
    const [currentTab, setCurrentTab] = useState(0)

    const [channels, setChannels] = useState([])
    const [loadingChannels, setLoadingChannels] = useState(false)

    const [visits, setVisits] = useState([])
    const [loadingVisits, setIsLoadingVisits] = useState(false)


    const [warning, setWarning] = useState(null)


    const { t } = useTranslation();
    const navigate = useNavigate();



    useEffect(() => {
        resizeNavBar()

        handleReload()
    }, [reseller])


    const handleReload = () => {
        loadChannels()
        loadVisits()
        setWarning(null)
        setCurrentTab(0)
    }


    const handleNewChannel = async (channel) => {
        return axios.post(`${URL_BACKEND}/reseller/channel/${user.authParams}`, channel)
            .then(response => {
                return response.data
            })
            .catch(e => {
                console.log("Error", e)
                return e.message
            });
    }
    const loadChannels = async () => {
        console.log("loadChannels: ", user)
        if (user) {
            setLoadingChannels(true)
            await axios.get(`${URL_BACKEND}/reseller/channels/${user.authParams}`)
                .then(response => {

                    setLoadingChannels(false)
                    console.log(response.data)
                    setChannels(response.data)

                }).catch(e => {
                    setLoadingChannels(false)
                    console.log("Error", e)
                    return e.message
                });

        }
    }

    const loadVisits = async () => {
        console.log("loadVisits: ", user)
        if (user) {
            setIsLoadingVisits(true)
            await axios.get(`${URL_BACKEND}/reseller/visits/${user.authParams}`)
                .then(response => {
                    setIsLoadingVisits(false)
                    console.log(response.data)
                    setVisits(response.data)
                }).catch(e => {

                    setIsLoadingVisits(false)
                    console.log("Error", e)
                    return e.message
                });
        }
    }



    const handleDeleteChannel = (channelId) => async () => {

        const aChannel = channels.find(ch => ch.id === channelId)

        const prompt = window.confirm(`¿Esta seguro que desea eliminar el canal ${aChannel.name} ${aChannel.account}?`)
        if (!prompt) return

        const resp = await axios.delete(`${URL_BACKEND}/reseller/channel/${channelId}/${user.authParams}`)
        if (resp.data.code === "OK") {
            console.log(resp)
            loadChannels()
        } else {
            setWarning("Error. Intente nuevamente o mas tarde.")
        }
    }


    const handleToggleChange = async (e) => {

        const channelId = e.target.value
        const status = e.target.checked

        if (!status) {
            const prompt = window.confirm("¿Esta seguro que desea desactivar este canal de promoción?")
            if (!prompt) return
        }

        const resp = await axios.put(`${URL_BACKEND}/reseller/channel/${channelId}/${user.authParams}`, { status: status })
        if (resp.data.code === "OK") {
            console.log(resp)
            loadChannels()
        } else {
            setWarning("Error. Intente nuevamente o mas tarde.")
        }

    }


    const handleAtrasClick = () => {
        setCurrentTab(0)
    }

    const getChannelVisit = (channelName, channelUrl) => {
        let total = 0;
        for (let i = 0; i < visits.length; i++)
            if (visits[i].url.includes(channelName?.toLowerCase()) || visits[i].url.includes(channelUrl?.toLowerCase()))
                total += 1
        return total
    }



    const getBgColor = (ch) => {
        if (ch.status) { return "bg-dark" } else { return "bg-secondary" }
    }

    if (loadingChannels) return <div className="text-center" style={{ paddingTop: "100px" }}><i className="spinner-border text-primary my-2 mr-1" role="status" />Cargando datos...</div>


    return (
        <div className="container small col-12 col-md-6" style={{ paddingTop: "50px", marginBottom: "60px" }} onClick={() => resizeNavBar()}>

            {currentTab == 0 &&
                <Zoom >

                    {channels.length > 0 ?
                        <div className="h4">CANALES DE PROMOCIÓN</div>
                        :
                        <div className="h4">COMIENZA A VENDER</div>
                    }


                    <div>


                        {channels.length > 0 &&
                            <div>

                                <div className="mb-4 small">
                                    <div>Lugares en los cuales debes promocionar Catwatchful.</div>
                                    <div className="">Puedes agregar o eliminar canales de promoción las veces que quieras.</div>
                                </div>




                                {visits.length >= 0 && <div className="my-4 small">
                                    <div className="text-warning"></div>
                                    <div>TU ENLACE DE VENTAS </div>
                                    <div className="text-warning">{'https://' + reseller.info.referLink}</div>
                                    <div>TUVO UN TOTAL DE:</div>
                                    <div className="font-weight-bold text-warning h5">  {visits.length} VISITAS</div>
                                </div>
                                }

                            </div>
                        }

                        <hr className="bg-light" />
                        {channels.map((ch, index) => (
                            // <Fade left >
                            <div>

                                <div data-toggle="collapse" data-target={`#collapse-${ch.id}`} aria-expanded="false" aria-controls={`collapse-${ch.id}`} key={index} className={` mt-3 mb-0 text-center p-1  d-flex justify-content-between ${getBgColor(ch)}`}
                                    style={{ borderRadius: '15px', border: `2px solid ${getChannelColor(ch.name)}` }}>


                                    <div className="mb-1 mt-2 ml-1" style={{ color: getChannelColor(ch.name) }}>
                                        <i className={getChannelIco(ch.name) + "  fa-3x"} />
                                        <div className="" style={{ lineHeight: "13px" }}> {ch.name}</div>

                                    </div>

                                    <div >
                                        <div className="text-light">{ch.targetName}</div>
                                        {/* <Link to={ch.url} target="_blank"><div className="font-weight-bold" style={{ color: getChannelColor(ch.name) }}>{ch.account}</div></Link> */}
                                        <div className="font-weight-bold" style={{ color: getChannelColor(ch.name) }}>{ch.account}</div>
                                        <div className="mt-0 mb-2"><span className="" >{getChannelVisit(ch.name, ch.url)}</span> visitas</div>
                                        <div style={{ height: "2px" }}><i className="fa fa-chevron-down h5 py-0" style={{ color: getChannelColor(ch.name) }} /></div>
                                    </div>

                                    <div className="mt-2" style={{ width: "80px" }}>

                                        <div className="custom-control custom-switch ">
                                            <input type="checkbox" className="custom-control-input" id={"switch" + index} value={(ch.id)} checked={ch.status} onChange={handleToggleChange} />
                                            {ch.status ?
                                                <label className="custom-control-label text-primary small pt-1" htmlFor={"switch" + index}>Activo</label>
                                                :
                                                <label className="custom-control-label text-secondary small pt-1" htmlFor={"switch" + index}>Inactivo</label>
                                            }
                                        </div>
                                        <div className="mt-1 text-danger" onClick={handleDeleteChannel(ch.id)}> <i className="fa fa-trash" /> <span className="small">Eliminar</span></div>
                                    </div>
                                    {/* <div className=""> Visitas: <spa2n>{ch.visits}</span></div> */}
                                </div>


                                {/* COLAPSE************************* */}
                                <div className="collapse" id={`collapse-${ch.id}`} >
                                    <div className="bg-dark">
                                        <div className="text-left">
                                            <div className="mb-0 mt-2 text-uppercase " >Promociona en <span className="font-weight-bold">{ch.account}</span></div>
                                            <div className="mt-1 pt-3 pb-0" style={{ backgroundColor: getChannelColor(ch.name) }}>
                                                {getChannelSteps(ch.name)}
                                                <div className="text-center pb-1 " data-toggle="collapse" data-target={`#collapse-${ch.id}`} aria-expanded="false" aria-controls={`collapse-${ch.id}`}>
                                                    <i className="fa fa-chevron-up text-dark mt-2 fa-2x" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* ******************************* */}
                            </div>
                        ))}
                    </div>

                    {channels.length == 0 ?
                        <div>

                            <div className="text-left p-3">
                                <p className="my-0">{t('Resources.tag1')} </p>
                                <p className="my-2"></p>
                                <p className="my-0">{t('Resources.tag3')}</p>
                                <p className="mt-2"> Para empezar a vender comienza agregando el canal de tu preferencia en el cual vas a promocionar Catwatchful. </p >
                            </div>
                            <button className="btn btn-danger btn-lg mt-4" onClick={() => setCurrentTab(1)}>Comenzar <i className="fa fa-flag-checkered" /></button>
                        </div>
                        :
                        <div className="text-center mt-4">
                            <button className="btn btn-primary mt-4" onClick={() => setCurrentTab(1)}>Nuevo canal <i className="fa fa-plus" /></button>
                        </div>
                    }
                </Zoom>
            }


            {currentTab == 1 &&
                <SelectChannel handleAtrasClick={handleAtrasClick} handleReload={handleReload} handleNewChannel={handleNewChannel} />
            }




        </div>
    )



}
