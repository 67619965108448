import Bounce from 'react-reveal/Bounce';

export function Step(props) {
    const number = props.number
    return (
        <Bounce>
            <span className="h5 bg-secondary text-dark rounded px-2"> PASO {number}</span>
        </Bounce>
    )
}


export function NavigationButtons(props) {
    return (
        <div>
            <button className="btn btn-primary btn-sm mr-2" onClick={props.handleBack}><i className="fa fa-chevron-left" /> Atras</button>
            <button className="btn btn-primary btn-sm" onClick={props.handleContinue}>Continuar <i className="fa fa-chevron-right" /></button>
        </div>
    )

}






