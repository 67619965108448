import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { resizeNavBar } from '../functions'
import MiContexto from '../MiContexto'
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import {useTranslation } from 'react-i18next';
import Settlement from "./Settlement";



export default function WithDraw(props) {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const reseller = useContext(MiContexto).reseller
    const [warning, setWarning] = useState(null)
    const [amount, setAmount] = useState(0)


    useEffect(() => {
        resizeNavBar()
        console.log("reseller: ", reseller)

        if (reseller) setAmount(reseller.saldo)
    }, [reseller])


    const handleContinue = () => {

        if (amount <= 0) {
            setWarning(t('WithDraw.montoMayor'))
            return
        }


        if (amount > parseInt(reseller.saldo)) {
            setWarning(t('WithDraw.noMoney'))
            return
        }




        const lanzarPaymentModal=async()=>{
            const sleepTime = (ms) => {return new Promise(resolve => setTimeout(resolve, ms))}
            setWarning(null)
            await sleepTime(200)
            window.$('#selectPaymentModal').modal();
        }
        lanzarPaymentModal()



        
        


    }


    if (!reseller) return null


    let minSales = 10
    let currentSales = reseller.ventas.length
    let percent = currentSales / minSales * 100

    return (

        <div style={{ paddingBottom: "40px" }} >


            <SelectPaymentPromt amount={amount} sales={reseller.ventas.length} />

            <div className="container " style={{ paddingTop: "40px" }} onClick={() => resizeNavBar()}>

                <Zoom>
                    <h4 className="text-success">{t('WithDraw.title')}</h4>
                    <span className="mr-2 text-light small rounded my-3">{t('WithDraw.ventas')} {currentSales} de {minSales}</span>

                    <div className="d-flex justify-content-center mb-3"  >
                        
                        <div className="progress " style={{ height: "18px", width: "250px" }}>
                            <div className="progress-bar bg-success" role="progressbar" style={{ width: percent + "%", fontSize: "10px" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"> {percent}%</div>
                        </div>
                    </div>
                </Zoom>

                <Settlement externalCall={false} />


                <Zoom>
                    <div className="d-flex justify-content-center mt-4">

                        <div className="text-center bg-dark rounded border border-secondary " style={{ width: "365px" }}>
                            <div className="mt-2 pt-2 h4 text-light">{t('WithDraw.montoRetirar')}</div>


                            {/* <hr className="bg-secondary" /> */}


                            <span className="mr-2 small "> {t('WithDraw.selectMonto')} </span>
                            <div className="d-flex justify-content-center mt-2">

                                <span className="mt-2 mr-1" style={{ fontSize: "20px" }}>U$S</span>
                                <input id="inputAmount" onChange={(e) => setAmount(e.target.value)}
                                    value={amount}
                                    style={{ width: "80px", fontSize: "20px" }}
                                    className="form-control text-center"
                                    type="number" min="1" max="1000"
                                    step="1" />


                            </div>

                            {warning && <div className="alert alert-danger small mt-2 mx-4 py-1" role="alert"><i className="fa fa-warning" />  {warning}</div>}


                            <div className="mx-2 py-1">
                               <button className="btn btn-success btn-block my-2" onClick={handleContinue}>{t('WithDraw.btnContinuar')}</button>
                            </div>
                        </div>
                    </div>
                </Zoom>

                <Fade><div className="d-flex justify-content-center my-3">

                    <div className="text-left px-2 py-4 text-muted" style={{ width: "380px" }}>
                        <i className="fa fa-warning mr-1" />{t('WithDraw.important')} 
                        <div className=" small">{t('WithDraw.important1')} </div>
                        <div className=" small">{t('WithDraw.important2')} </div>
                        <div className=" small">{t('WithDraw.important3')} </div>
                        <div className=" small">{t('WithDraw.important4')} </div>
                    </div>
                </div>
                </Fade>


                <div id="credit-cards-sections">



                </div>

            </div>

            </div>
    )




    function SelectPaymentPromt(props) {

        const { amount, sales } = props

        const [steep, setSteep] = useState(1)
        const minVtas = 10

        useEffect(() => {
            console.log("steep: ", steep)
        }, [steep])


        return (
            <div className="modal fade text-dark" id="selectPaymentModal" tabIndex="-1" role="dialog" aria-labelledby="selectPaymentModal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h5 className="modal-title text-success" id="exampleModalLabel">{t('WithDraw.retirarDinero')} {amount}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        {steep == 1 && <Steep1 />}
                        {steep == 2 && <Steep2 />}
                        {steep == 3 && <Steep3 />}


                    </div>
                </div>
            </div>
        )


        function Steep1() {

            const [selectedOption, setSelectedOption] = useState(null);
            const [terms, setTerms] = useState(true)
            const [warning, setWarning] = useState(null)

            const [loading, setLoading] = useState(false)

            const handleOptionChange = (e) => {
                setSelectedOption(e.target.value);
                console.log(e.target)
            };

            const handleCheckBoxClick = (e) => {
                window.open("https://catwatchful.com/main/terms-of-use/");
            }

            const sleepTime = (ms) => {
                return new Promise(resolve => setTimeout(resolve, ms));
            }

            const handleContinue = async () => {

                if (!terms) {
                    setWarning(t('WithDraw.mustAceptTerms'))
                    return
                }

                if (!selectedOption) {
                    setWarning(t('WithDraw.selectTransferMetod') )
                    return
                }

                if (sales < minVtas) {
                    setLoading(true)
                    await sleepTime(3000)
                    setLoading(false)

                    setSteep(2)
                    return
                } else {
                    //ENVIAR DINERO!
                    setLoading(true)
                    await sleepTime(3000)
                    setLoading(false)

                    setSteep(3)
                    return
                }


                //window.$('#selectPaymentModal').modal('hide');

            }

            const paymetMethods = [{ name: t('WithDraw.bankTransfer'), icon: "fa-university", id: "opt1" },
            { name: t('WithDraw.retiroAtarjeta') , icon: "fa-credit-card", id: "opt2" },
            { name: t('WithDraw.paypal'), msj: "(Comisión 5%) ", icon: "fa-paypal", id: "opt4" },
            { name: t('WithDraw.bitcoin'), icon: "fa-btc", id: "opt5" },
            { name: t('WithDraw.giroPostal'), msj: "(Westernunion, Moneygram)", icon: "fa-money", id: "opt3" }]

            return (
                <div>
                    <div className="text-left ml-3">
                        <h5 className="mb-3 mt-1 text-primary">{t('WithDraw.comoRecibir')}</h5>

                        {paymetMethods.map((met, index) => {
                            return (
                                <div className="form-check mt-1" key={index}>
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        id={met.id}
                                        value={met.id}
                                        checked={selectedOption === met.id}
                                        onChange={handleOptionChange}
                                    />
                                    <label className="form-check-label" htmlFor={met.id}>
                                        <i className={`fa ${met.icon} mr-1`} />
                                        {met.name} {met.msj && <span className="small">{met.msj}</span>}
                                    </label>
                                </div>
                            )
                        })}


                        <div className="modal-body text-left small">
                            <div className="text-secondary small">{t('WithDraw.msjInfo')}</div>
                        </div>

                        {warning && <div className="alert alert-danger small mx-4 py-1" role="alert"><i className="fa fa-warning" />  {warning}</div>}

                        <div className="form-check mt-1">
                            <input type="checkbox" className="form-check-input" id="Check1" checked={terms} onChange={() => setTerms(!terms)} />
                            <label className="form-check-label cheked text-secondary small" htmlFor="Check1" onClick={handleCheckBoxClick}>{t('WithDraw.agreeTerms')}</label>
                        </div>

                        {!loading ? <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">{t('WithDraw.btnCerrar')}</button>
                            <button type="button" className="btn btn-primary" onClick={handleContinue} >{t('WithDraw.btnContinuar2')}</button>
                        </div>
                            :
                            <div className="text-center"><i className="spinner-border text-primary my-2" role="status" /></div>
                        }

                    </div>
                </div>
            )
        }

        function Steep2() {

            return (
                <div>

                    <div className="modal-body text-left small">

                        <div className="h6 text-danger"><i className="fa fa-warning mr-1" />{t('WithDraw.ventasInsuficientes')} </div>

                        <div className="mt-0">{t('WithDraw.ventasInsuficientes2')}</div>

                        <div className="text-danger mt-2">{t('WithDraw.ventasActuales')} {sales}</div>
                        <div className="text-danger">{t('WithDraw.minimoVentas')} {minVtas}</div>

                        <div className="mt-2">{t('WithDraw.vtasPendientes',{vtasRestantes: minVtas - sales})} </div>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => setSteep(1)}>{t('WithDraw.btnCerrar2')}</button>
                    </div>
                </div>
            )
        }


        function Steep3() {

            const [loading, setLoading] = useState(false)

            return (
                <div>

                    <div className="modal-body text-left small">

                        <div className="h6 text-primary">{t('WithDraw.confirmarTransfer')} </div>

                        <div className="mt-3">{t('WithDraw.metodoSelected')} </div>

                        <div className="mt-3">{t('WithDraw.datosTransfer')} </div>

                        <div>{t('WithDraw.direccion')} </div>
                        <div>{t('WithDraw.telefono')} </div>
                        <div>{t('WithDraw.cuenta')} </div>
                        <div>{t('WithDraw.email')} </div>

                    </div>
                    <div className="modal-footer">
                        {!loading ? <button type="button" className="btn btn-primary" onClick={() => setLoading(true)} >{t('WithDraw.btnContinuar3')}</button>
                            :
                            <i className="spinner-border text-primary my-2" role="status" />
                        }
                    </div>
                </div>
            )
        }




    }

    
    

}


