import React, { useEffect, useState, useRef, useContext } from "react";
import Zoom from 'react-reveal/Zoom';
import Bounce from 'react-reveal/Bounce';
import Fade from 'react-reveal/Fade';
import { useNavigate, Link } from 'react-router-dom';
import { Step, NavigationButtons } from './SellingComponents'
import MiContexto from '../../MiContexto'
import TabInstagram from './TabInstagram'
import TabFacebook from './TabFacebook'
import TabYoutube from './TabYoutube'
import TabWebsite from './TabWebsite'
import TabTikTok from './TabTikTok'
import { getChannelColor, getChannelIco, getChannelDescription } from "./channelOptions";

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'


export default function SelectChannel(props) {



    const [currentTab, setCurrentTab] = useState("SelectChannel")
    // let webSiteLink = "https://catwatchful.es/"
    // if (currentLanguage === 'en') webSiteLink = "https://catwatchful.com/"
    // const spanStyle = { padding: '20px', background: '#FFF', color: '#000000' }
    // const divStyle = { display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundSize: 'cover', height: '200px' }


    const slideOptions = [
        { label: "Instagram", color: getChannelColor("Instagram"), ico: getChannelIco("Instagram"), image: 'images/baner1.jpg', text: getChannelDescription("Instagram") },
        { label: "Facebook", color: getChannelColor("Facebook"), ico: getChannelIco("Facebook"), image: 'images/baner2.jpg', text: getChannelDescription("Facebook") },
        { label: "Youtube", color: getChannelColor("Youtube"), ico: getChannelIco("Youtube"), image: 'images/baner1.jpg', text: getChannelDescription("Youtube") },
        { label: "Website", color: getChannelColor("Website"), ico: getChannelIco("Website"), image: 'images/baner3.jpg', text: getChannelDescription("Website") },
        { label: "TikTok", color: getChannelColor("TikTok"), ico: getChannelIco("TikTok"), image: 'images/baner4.jpg', text: getChannelDescription("TikTok") },

        //     { label: "EmailMarketing", color: getChannelColor("EmailMarketing"), ico: getChannelIco("EmailMarketing"), image: 'images/baner2.jpg', text: getChannelDescription("EmailMarketing")  },
        //     { label: "Otro", color: getChannelColor("Otro"), ico:  getChannelIco("Otro"), text: getChannelDescription("Otro")  }
        // 
    ]


    return (
        <div>
            {currentTab == "SelectChannel" &&
                <div>
                    <Step number='1' />
                    <Fade left >
                        <div className="mb-1 mt-4 h5">¿Como deseas promocionar?</div>
                        <div className="slide-container ">
                            <Slide>
                                {slideOptions.map((ch, index) => (
                                    <div className="px-4 pb-2 pt-1 rounded"
                                        // style={{ backgroundColor: ch.color,height:"300px",backgroundSize: 'cover',backgroundImage: `url(${ch.image})` }
                                        style={{ backgroundColor: ch.color, height: "530px", backgroundSize: 'cover' }
                                        } key={index} >
                                        <div className="rounded mt-1 mb-0 text-center " >
                                            <span className="h3 text-uppercase" ><i className={ch.ico + " fa-2x"} /> {ch.label}</span>
                                        </div>
                                        <div className=" text-left mt-0" >{ch.text}</div>
                                        <button onClick={() => setCurrentTab(ch.label)} className="btn btn-outline-dark" style={{ marginTop: "10px" }}>SELECCIONAR</button>
                                    </div>
                                ))}
                            </Slide>
                        </div>
                        <i className="fa fa-arrow-circle-left text-light fa-2x" style={{ marginTop: "40px" }} onClick={props.handleAtrasClick} />
                    </Fade></div>
            }

            {currentTab == "Instagram" && <TabInstagram setCurrentTab={setCurrentTab} handleNewChannel={props.handleNewChannel} handleReload={props.handleReload} />}
            {currentTab == "Facebook" && <TabFacebook setCurrentTab={setCurrentTab} handleNewChannel={props.handleNewChannel} handleReload={props.handleReload} />}
            {currentTab == "Youtube" && <TabYoutube setCurrentTab={setCurrentTab} handleNewChannel={props.handleNewChannel} handleReload={props.handleReload} />}
            {currentTab == "Website" && <TabWebsite setCurrentTab={setCurrentTab} handleNewChannel={props.handleNewChannel} handleReload={props.handleReload} />}
            {currentTab == "TikTok" && <TabTikTok setCurrentTab={setCurrentTab} handleNewChannel={props.handleNewChannel} handleReload={props.handleReload} />}

            

        </div>
    )
}