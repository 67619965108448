import React, { useEffect, useState, useRef, useContext } from "react";
import { Link } from 'react-router-dom'
import Moment from 'react-moment';
import Zoom from 'react-reveal/Zoom';
import { useNavigate } from 'react-router-dom';
import {useTranslation } from 'react-i18next';

import { resizeNavBar } from '../functions'
import MiContexto from '../MiContexto'
import NavBar from "./NavBar";


export default function Header(props) {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const reseller = useContext(MiContexto).reseller

    const [salesNotify, setSalesNotify] = useState([])

    useEffect(() => {

        if (reseller.ventas.length > 0) {
            let salesNotifyAux = reseller.ventas.filter(venta => venta.notify === 1)
            setSalesNotify(salesNotifyAux)
        }

    }, [reseller])

    if (!reseller) return null



    return (

        <React.Fragment>

            {/* HEAD */}
            <div className="d-flex justify-content-between  fondoTransOscuro fixed-top" >

                {reseller && <NavBar reseller={reseller} />}

                {reseller && <div className="mt-2 mr-1">
                    {/* ACTIVE SECTION ****************************/}
                    {reseller.info.avatar &&
                        <Link to="dashboard"> <img src={reseller.info.avatar} className="rounded-circle" style={{ width: "30px", height: "30px" }} alt="Avatar" /></Link>
                    }
                    <Link to="dashboard"><span className="p-2 text-light" >{reseller.info.email}</span></Link>

                    <span onClick={props.handleLogout} className=""><i className="fa fa-sign-out mr-1 p-0 " /></span>

                    <div className="text-light ml-1" style={{ fontSize: "14px" }} >
                        <Link to="withdraw"><span className="mr-3 badge badge-success text-dark" >{t('Header.credit')}: U$S {reseller.saldo}</span></Link>
                        <Link to="sales"><span className="badge badge-warning" >{t('Header.ventas')}: {reseller.ventas.length}</span></Link>
                        {salesNotify.length > 0 &&  <Link to="sales"><span className="badge badge-danger ml-1 mb-2">{t('Header.new')}: {salesNotify.length}</span></Link>}
                    </div>

                </div>
                }
                {/* *********************************** */}

            </div>

        </React.Fragment>

    )
}