import React, { useState, useEffect, useContext } from "react";

import { RotatingLines } from 'react-loader-spinner'
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
import Flip from 'react-reveal/Flip';


import MiContexto from '../MiContexto'

import { useNavigate, Link } from 'react-router-dom';

import { resizeNavBar } from '../functions'
import Sales from "./Sales";
import Settlement from "./Settlement";

import { QRCodeCanvas } from 'qrcode.react';
import clipboardCopy from 'clipboard-copy';

import {useTranslation } from 'react-i18next';


function Dashboard(props) {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const reseller = useContext(MiContexto).reseller



    const copiarTexto = (aText) => {
        clipboardCopy(aText)
            .then(() => {
                alert(t('Dashboard.linkCopied'));
            })
            .catch((error) => {
                console.error('Error al copiar el texto: ', error);
            });
    };


    useEffect(() => {
        resizeNavBar()

    }, [reseller])



    if (!reseller) { return (<div className="mt-4"> <RotatingLines color="#00FF00" height={60} width={60} > </RotatingLines>{t('Dashboard.loadingData')} </div>) }


    return (
        <div className="pb-4">

            <Fade >
                <div className="pb-4 pt-3" onClick={() => resizeNavBar()} >

                    {/* NAME & AVATAR */}
                    <Flip> <img src={reseller.info.avatar} className="rounded-circle mt-4" style={{ width: "120px", height: "120px" }} alt="Avatar" /> </Flip>
                    <div className="mt-1 text-warning" >{reseller.info.nombres}
                    </div>


                    {/* ACCOUNT STATE */}
                    {reseller.info.estado === 0 && <div className="alert alert-danger mx-2" role="alert">
                        <strong><i className="fa fa-warning mr-2" />{t('Dashboard.pendingAprovalTitle')}</strong>
                        <div className="mb-0 pb-0 small">{t('Dashboard.pendingAprovalMsj')}</div>
                    </div>}
                    {reseller.info.estado === 1 &&
                        <div className="small">
                            <span className="badge badge-success">{t('Dashboard.accountAproved')} <i className="fa fa-check ml-0 " /></span >
                            <div className="text-light small mx-2">{t('Dashboard.currentLevel',{level:reseller.info.nivel, percent:reseller.porcentajeGanancia})}</div>
                        </div>}




                    <Bounce>
                        <div className="text-info mt-3">
                            <div className="text-light mb-1">{t('Dashboard.resellerCode')} </div>
                            <span className="bg-warning rounded border border-dark py-2 px-2 text-dark font-weight-bold"> {reseller.info.referCode}</span>
                        </div>
                    </Bounce>


                    <div className="mt-3">
                        <Bounce>
                            {/* <i className="fa fa-internet-explorer mr-2" /> */}
                            <div className="text-light mb-1">{t('Dashboard.yourLink')}</div>


                            <div>
                                <Link to={`https://${reseller.info.referLink}`} target="_blank"> <span className="bg-warning rounded border border-dark py-2 px-2 text-dark font-weight-bold "> {reseller.info.referLink}</span></Link>
                                <i className="fa fa-clone ml-2" onClick={() => copiarTexto(reseller.info.referLink)} />
                            </div>
                            <div className="text-secondary small mt-2" >{t('Dashboard.visits')} {reseller.visitas} <i className="fa fa-line-chart" /></div>

                            <div className="mt-2 mb-3">
                                <div className="text-light">{t('Dashboard.yourQr')}</div>
                                <QRCodeCanvas
                                    value={`https://${reseller.info.referLink}`} renderAs="canvas"
                                    includeMargin={true}
                                    size={80} />
                            </div>

                        </Bounce>


                        <Settlement externalCall={true} />

                        <Sales externalCall={true} />


                    </div>

                </div>
            </Fade>



            </div>
    )





}
export default Dashboard

