import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';

import {  useTranslation } from 'react-i18next';

function ForgotPass(props) {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [advertencia, setAdvertencia] = useState();
    const [isLoading, setIsLoading] = useState(false);


    const handleSubmit = async (event) => {

        event.preventDefault()

        if (username) {
            setAdvertencia(null)

            setIsLoading(true);
            const resp = await props.handleForgotPass(username)
            setIsLoading(false);

            if (resp.code == 'OK') {
                alert(t('Forgot.requestSend') )
                navigate("/")
            } else {
                setAdvertencia(resp.message)
            }

        } else {
            setAdvertencia( t('Forgot.putEmail'))
        }
    }


    return (
        <div className="container" style={{ marginTop: '120px' }}>


            <div className='d-flex justify-content-center'>
                <div >
                    <Fade><img src="./images/logo.svg" className="img-responsive " width="120px" alt="CatWatchful" /></Fade>
                    <Fade> <h5 className="mt-2 text-uppercase" style={{ color: "#8EDC80" }}>{t('Login.resellerProgram')}</h5></Fade>
                </div>

            </div>

            <Fade>
                <div className="d-flex justify-content-center">
                    <div className="fondoTransOscuro rounded mt-4 col-md-5 pb-4">

                        <div className="pt-3 text-danger"><i className="fa fa-lock text-right mr-1 " />{t('Forgot.resetPassword')}</div>


                            <div className='d-flex justify-content-center px-4 pt-3'>
                                <div className='form-group' style={{ width: '400px' }}>

                                    <div className="input-with-icon">
                                        <input
                                            className='form-control my-1'
                                            type="text"
                                            placeholder="E-mail"
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                        />
                                        <i className='icon fa fa-envelope text-secondary' />
                                    </div>



                                    {advertencia && <div className="alert alert-danger small my-2" role="alert"><i className="fa fa-warning" />  {advertencia}</div>}

                                    {!isLoading ?
                                        <div className="d-flex mt-2">
                                            < button className='form-control btn btn-primary mt-1 mr-1' onClick={() => navigate("/")}>{t('Forgot.back')}</button>
                                            < button type="submit" className='form-control btn btn-success mt-1' onClick={handleSubmit}>{t('Forgot.continue')}</button>
                                        </div>
                                        :
                                        <button className='form-control btn btn-success mt-1' disabled={true}><i className="fa fa-spinner fa-spin" /> </button>
                                    }

                                </div>
                            </div>

              
                    </div>
                </div>
            </Fade>


        </div>
    )

}
export default ForgotPass